//------------------------------------------------------------------------
// Font style helper classes
//
// sass-lint:disable no-important
// scss-lint:disable ImportantRule
//------------------------------------------------------------------------
.f {

  // Scale the body copy font-size relative to the viewport width
  // Note: This won’t affect h1–h6, which are set in rems (see _rich-text-headings.scss).
  &-scale {
    @include text-size-scale;
  }

  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }

  &-bold {
    font-weight: bold;
  }

  // Makes numbers all the same height (requires OpenType support)
  // This is nice for phone numbers and addresses, as well as data tables.
  // &-lining-figures {
  //   font-feature-settings: "lnum", "onum" off;
  // }

  // Web fonts
  //---------------------------------
  &-chiavettieri {
    font-family: $chiavettieri;
  }

  &-lato {
    font-family: $lato;
  }

  // Custom text styles
  //---------------------------------
  &-title {
    @include page-title;
  }

  &-intro {
    @include intro-text;
  }

  &-supertitle{
    @include supertitle;
  }

  &-highlight {
    @include text-highlight;
  }

  &-caption {
    @include image-caption;
  }

  // Default heading styles
  //---------------------------------
  &-h2 {
    @include h2;
  }

  &-h3 {
    @include h3;
  }

  &-h4 {
    @include h4;
  }

  &-h5 {
    @include h5;
  }

  &-h6 {
    @include h6;
  }
} // end f
