// -------------------------------------------------------------------------
// Base Styles
// -------------------------------------------------------------------------
:root {
  @media print {
    --icon-color: #000;
    --theme-color: #fff;
    --theme-text-color: #000;
  }
}

// 1. Set bg color on html instead of body to ensure it always fills the page
//    http://css-tricks.com/just-one-of-those-weird-things-about-css-background-on-body/
html {
  background-color: $bg-default;// [1]
  color: $c-default;
  scroll-behavior: smooth;

  // Diable smooth scrolling if user has enabled “reduce motion” in their browser/OS
  // https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion#user_preferences
  // stylelint-disable media-feature-name-no-unknown
  @media screen and (prefers-reduced-motion: reduce) {
    scroll-behavior: auto !important;
  }

  // Support iOS accessibility text size
  // Note: Only apply on iOS due to bug with Safari 14 on Big Sur
  // https://webkit.org/blog/3709/using-the-system-font-in-web-content/
  // https://www.interactiveaccessibility.com/blog/text-resizing-web-pages-ios-using-dynamic-type
  // https://dev.to/colingourlay/how-to-support-apple-s-dynamic-text-in-your-web-content-with-css-40c0
  // https://gist.github.com/colingourlay/d95908ec5cd4854c7a5afa06f3989479
  // stylelint-disable font-family-no-missing-generic-family-keyword
  &.ua-ios {
    @supports (font: -apple-system-body) {
      font: -apple-system-body;
    }
  }

  // Add extra margin above jump link targets to offset the fixed header
  // https://css-tricks.com/fixed-headers-on-page-links-and-overlapping-content-oh-my/
  // https://caniuse.com/mdn-css_properties_scroll-padding
  @supports (scroll-padding-top: 0) {
    $vert-offset: 20px;
    scroll-padding-top: calc(var(--layout-header-height) + $vert-offset);

    @include fs-min-width($layout-nav-bp) {
      scroll-padding-top: calc(var(--layout-header-height) + $vert-offset + 50px);// account for sticky subnav
    }
  }

  // Allow long words to wrap on small devices
  @include fs-max-width(320px) {
    @include fs-break-word;
  }

  // Prevent page from scrolling (required by freeze-scroll.js)
  // sass-lint:disable no-important
  // scss-lint:disable ImportantRule
  &.js-no-scroll {
    height: 100% !important;

    body {
      height: 100%; // don’t add !important, will be overridden by inline styles
      overflow: hidden !important;
      position: fixed !important;
      width: 100% !important;
    }
  }
}

// 2. Set “font-family” on body since we’re applying “-apple-system-body” on html element
// 3. Prevent browsers from automatically adding hyphens by default
body {
  font-family: $body-copy-font;// [2]
  hyphens: manual;// [3]
  // overflow-x: hidden;// this breaks “position: sticky”

  // SVG sprite fix
  // https://github.com/patrickxchong/eleventy-plugin-svg-sprite/issues/12
  // Issue demo https://jsbin.com/rahucobubu/edit?html,css,output
  & > div[style] {
    overflow: hidden;
    position: absolute;
  }
} // end body

// Prevent back-to-top button from overlapping content in mobile view
:where(main > :last-child) {
  @include fs-max-width($layout-footer-wide-wp - 1px) {
    padding-bottom: fs-rem($layout-back-to-top-button-height);
  }
}

// Opinionated global styles not included in CSS reset
// Note: Use :where() to reduce specificity to zero,
//       making it easier to override later.
:where(a) {
  @include link-base;
}

// SVGs without a class or inline “fill” attribute should
// inherit fill color, in additional to external link icons.
svg:not([fill]):not([class]),
.icon-external {
  fill: currentColor;
}

// Prevent zoom on iOS (requires adding "ios" class to <html>)
// http://www.456bereastreet.com/archive/201212/ios_webkit_browsers_and_auto-zooming_form_controls/
// sass-lint:disable no-important
// scss-lint:disable ImportantRule
.ua-ios {
  input,
  textarea,
  select {
    // Use max() to make it possible to override in component styles
    @supports (font-size: #{"max(1em, 16px)"}) {
      font-size: #{"max(1em, 16px)"};
    }
  }
}

// Disable animations accessibly for users requesting reduced motion,
// as well as devices with a slow refresh rate.
// NOTE: Not all animations are bad, some may need to override this.
// https://web.dev/prefers-reduced-motion/
// https://piccalil.li/blog/a-modern-css-reset
// https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
// https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
// https://developer.mozilla.org/en-US/docs/Web/CSS/@media/update-frequency
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

// -------------------------------------------------------------------------
// Remove text-shadow in selection highlight : http://h5bp.com/i
// -------------------------------------------------------------------------
// ::selection {
//   background-color: $some-custom-color;
//   text-shadow: none;
// }

//------------------------------------------------------------------------
// Hide outlines on focused elements using “what-input” JS plugin
//------------------------------------------------------------------------
[data-whatintent="mouse"],
[data-whatintent="touch"] {
  *:focus {
    outline: none;
  }
}

//------------------------------------------------------------------------
// Hide outlines when clicking/tapping buttons
// https://www.sarasoueidan.com/blog/focus-indicators/
// Only works in browsers that support :focus-visible
// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
// Note: We must disable “focus-visible-pseudo-class” in PostCSS
// https://github.com/csstools/postcss-plugins/tree/main/plugins/postcss-focus-visible
//------------------------------------------------------------------------
// Optional: Fallback styles for older browsers
// button:focus {}

// Styles for non-keyboard focus (i.e. click/tap)
button,
[role="button"],
[type="button"] {
  &:focus:not(:focus-visible) {
    outline: none;
  }
}
