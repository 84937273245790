//------------------------------------------------------------------------
// Footer
//------------------------------------------------------------------------
.Footer {
  $bp-wide: $layout-footer-wide-wp;
  $bp-card-row: 860px;
  position: relative;

  &-backToTop,
  &-info {
    --button-bg-color: #{$bg-navy};
    --button-border-color: #fff;
    --button-hover-bg-color: #{$bg-navy-dark};
    --button-hover-border-color: #fff;
  }

  &-backToTop {
    --button-border-color: #{$bg-blue-light};
    --button-hover-border-color: #{$bg-blue-light};

    @include fs-max-width($bp-wide - 1px) {
      align-items: center;
      bottom: 100%;
      display: flex;
      justify-content: space-between;
      position: absolute;
      right: 0;
      z-index: fs-zindex('back-to-top');

      &:after {
        padding-right: 0;
        transform: rotate(-90deg);
      }

      // Don’t animate up arrow
      @include fs-attention {
        &:after {
          mask-position: 0 center;
        }
      }
    }

    @include fs-min-width($bp-wide) {
      bottom: 0;
      left: 0;
      position: fixed;
      transform: rotate(-90deg) translateY(100%);
      transform-origin: bottom left;
      z-index: fs-zindex('back-to-top');
    }
  }// end backToTop

  &-image {
    @include fs-scale(height, (375px: 217px, $bp-wide: 579px));

    img {
      height: 100%;
      object-fit: cover;
      object-position: 50% 20%;// magic number to keep subject centered
      position: relative;
      width: 100%;
    }
  }

  &-wrap {
    padding-bottom: fs-rem(80px);
  }

  &-cards {
    display: flex;
    gap: 55px 22px;
    max-width: 1020px;

    @include fs-max-width($bp-card-row - 1px) {
      flex-wrap: wrap;
    }
  }

  &-card {
    background: $bg-offwhite;
    color: $bg-navy;
    margin-top: fs-rem(-20px);
    padding: 30px 20px;
    width: 100%;

    @include fs-min-width($bp-card-row) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-top: fs-rem(-60px);
      padding: 40px 90px 40px 40px;
      width: 50%;
    }

    &-title {
      @include fs-scale(font-size, (
        375px: 24px,
        $bp-card-row: (40px 36px),
        1130px: 44px,
      ));
      color: $bg-navy;
      font-family: $chiavettieri;
      font-weight: bold;
      line-height: 1;
    }

    &-description {
      color: $bg-navy-dark;
      font-size: fs-scale-clamp((360px: 18px, $layout-text-scale-end-bp: 20px));
      line-height: 1.5;
    }

    &-cta {
      @include fs-min-width($bp-card-row) {
        margin-top: auto;
      }
    }
  }

  &-info {
    margin-top: fs-scale-clamp((375px: 57px, $bp-wide: 78px));

    @include fs-min-width($bp-wide) {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      gap: 72px;
    }
  }

  &-logo {
    color: currentColor;
    display: block;
    width: 262px;
    margin-bottom: fs-rem(23px);

    @include fs-attention {
      color: $c-gray-light;
    }

    &-image {
      --theme-text-color: currentColor;
      fill: currentColor;
    }
  }// end logo

  &-donate {}

  &-beneath {
    &-text {
      @include fs-scale(font-size, (320px: 24px, $layout-text-scale-end-bp: 26px));
      margin-bottom: fs-rem(46px);
      max-width: 674px;
      letter-spacing: 0.3px;
      line-height: 1.43;

      @include fs-max-width($bp-wide - 1px) {
        margin-top: fs-rem(55px);
      }
    }
  }

  &-newsletter {
    &-form {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    &-heading {
      display: block;
      font-size: 16px;
      letter-spacing: 0.1em;
      padding-bottom: 15px;
      text-transform: uppercase;
    }

    &-row {
      flex-grow: 1;
      max-width: fs-rem(325px);
    }

    &-input {
      border: none;
      display: block;
      padding: 11px 16px;
      width: 100%;
    }

    &-submit {
      border-radius: 0;// iOS
      flex-shrink: 0;
    }
  }

  &-terms {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: fs-rem(70px);

    @include fs-min-width($bp-wide) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &-term {
    color: #fff;

    &-malala,
    &-uppercase {
      color: #fff;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }

  &-socials {
    display: flex;
    gap: 18px;
  }

  &-social {
    &-icon {
      color: #fff;
      height: 20px;
      width: 20px;
    }
  }
} // end Footer
