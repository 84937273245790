//------------------------------------------------------------------------
// Layout mixins
//------------------------------------------------------------------------
// Allow element to span viewport width regardless of parent width
// NOTE: These styles only work when the parent wrapper is centered.
// NOTE: These styles will break blocks with parallax backgrounds (e.g. cover)
@mixin layout-fullwidth($important: false) {
  left: 50% #{if($important, ' !important', '')};
  position: relative #{if($important, ' !important', '')};
  transform: translateX(-50%) #{if($important, ' !important', '')};
  width: 100vw #{if($important, ' !important', '')};
}
