// -------------------------------------------------------------------------
// Rich-text styles for post body
//
// Uses opt-in typography technique (see http://css-tricks.com/opt-in-typography/)
//
// scss-lint:disable ImportantRule, NestingDepth, SelectorDepth
// sass-lint:disable no-important, force-element-nesting
// -------------------------------------------------------------------------
@mixin richtext() {
  @include fs-clearfix;
  line-height: $body-copy-line-height;

  // Only apply rich text styles to direct children to avoid
  // conflicts with custom components.
  & > {
    *:where(:first-child) {
      margin-top: 0;
    }

    *:where(:last-child) {
      margin-bottom: 0;
    }

    p {
      margin-top: 1em;

      &:not(:last-child) {
        margin-bottom: 1em;
      }

      // Custom paragraph block style
      //
      // &.is-style-intro {
      //   @include intro-text;
      // }

      // Hide any empty paragraphs inadvertantly created in the rich text editor
      &:empty {
        display: none !important;
      }
    }

    // Update header mixins in `rich-text-headings.scss`
    h2 {
      // Use :where() to reduce specificty so the heading helper classes
      // (e.g. “f-h2”) will override the default heading styles.
      @at-root :where(#{&}) {
        @include h2($include-margin: true);
      }
    }

    h3 {
      @at-root :where(#{&}) {
        @include h3($include-margin: true);
      }
    }

    h4 {
      @at-root :where(#{&}) {
        @include h4($include-margin: true);
      }
    }

    h5 {
      @at-root :where(#{&}) {
        @include h5($include-margin: true);
      }
    }

    h6 {
      @at-root :where(#{&}) {
        @include h6($include-margin: true);
      }
    }

    // Remove top margin on paragraphs following headings
    :where(h2, h3, h4, h5, h6) + p {
      margin-top: 0;
    }

    // Reduce top margin on adjacent headings
    // :where(h2, h3, h4, h5, h6) + :where(h2, h3, h4, h5, h6) {
    //   margin-top: 1rem;
    // }

    // Lists
    // Note: Use “::marker” to avoid setting “list-style: none;” which
    //       causes VO in Safari to drop the list semantics.
    // https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html
    //---------------------------------
    // Lists
    :where(ol, ul) {
      $list-item-margin: 1em;
      margin-bottom: 1em;
      margin-top: 1em;

      &[data-columns] {
        margin-bottom: fs-rem(40px);
        margin-top: fs-rem(40px);
      }

      :where(ol, ul) {
        margin-bottom: $list-item-margin;
        margin-top: $list-item-margin;
      }

      li {
        line-height: 1.25;

        &:not(:first-child) {
          margin-top: $list-item-margin;
        }

        p {
          line-height: $body-copy-line-height;
          margin-top: 1em;

          &:not(:last-child) {
            margin-bottom: 1em;
          }
        }
      }
    }

    // Unordered list styles
    ul,
    :where(ol, ul) ul {
      $bullet-size: 18px;
      --bullet-size: #{fs-rem($bullet-size)};
      padding-left: 0;
      list-style: disc;

      & > li {
        background-image: fs-svg-url('circle-bullet', (fill: $bg-gold));
        background-position: 0 fs-em(5px, $body-copy-max-size);
        background-repeat: no-repeat;
        background-size: var(--bullet-size);
        padding-left: fs-rem($bullet-size + 20px);

        // Hide default marker
        // https://github.com/Threespot/lighthouse-guild/issues/172
        &::marker {
          color: transparent;
        }
      }

      // 2nd level
      ul {
        --bullet-size: #{fs-rem(14px)};

        // 3rd level and deeper
        ul {
          --bullet-size: #{fs-rem(9px)};
        }
      }
    }

    // Ordered list styles
    ol,
    :where(ol, ul) ol {
      padding-left: 3ch;
      list-style: decimal;

      & > li {
        &::marker {
          color: $c-blue;
          font-family: $chiavettieri;
        }
      }

      // 2nd level
      ol {
        list-style: lower-alpha;

        // 3rd level
        ol {
          list-style: lower-roman;

          // 4th level and deeper
          ol {
            list-style: decimal;
          }
        }
      }
    }

    // Definition list
    dl {
      &:not(:last-child) {
        margin-bottom: fs-em(71px, $body-copy-max-size);
      }

      dt {
        margin-bottom: fs-rem(3px);

        &:not(:first-child) {
          margin-top: fs-em(31px, $body-copy-max-size);
        }
      }

      dd {}
    }// end dl

    table {
      @include table-styles;
      margin-bottom: 2em;
      margin-top: 2em;
    }

    picture {
      display: block;
      margin-bottom: 1em;

      img {
        display: block;
        width: 100%;
      }
    }

    hr {
      background-color: $bg-gold;
      border: 0;
      display: block;
      height: 1px;
      margin-bottom: fs-rem(60px);
      margin-top: fs-rem(60px);

      &:nth-of-type(3n+2) {
        background-color: $bg-pink;
      }

      &:nth-of-type(3n+3) {
        background-color: $bg-green-light;
      }
    }
  } // end children

  // Inline elements (not direct children of “u-richtext”)

  // Ignore links with classes to avoid conflicts with custom components
  a:where(:not([class])) {
    @include link-richtext;
  }

  b,
  strong {
    font-weight: bold;
  }

  sub,
  sup {
    font-size: 0.8em;
    left: -1px;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: $monospace;
  }

  pre {
    font-family: inherit; // override CSS reset styles
    margin-bottom: 1em;
    margin-top: 1em;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-word;

    code {
      font-family: $monospace;
      font-size: fs-rem(16px);
    } // end code
  } // end pre

  // Set to “inline-block” to allow line-height to adjust
  small {
    display: inline-block;
  }
} // end richtext
