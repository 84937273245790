.DefaultHeader {
  $bp-small: 768px;// show icons
  $bp-medium: 1076px;
  @include fs-scale(margin-bottom, (320px: 102px, $bp-medium: 92px));
  @include fs-scale(padding-bottom, (320px: 51px, $bp-medium: 185px));
  @include fs-scale(padding-top, (320px: 28px, $bp-medium: 165px));
  background-color: $bg-gray-light;
  position: relative;

  &-has-image {
    @include fs-scale(margin-bottom, (320px: 272px, $bp-medium: 144px));
  }

  &-wrap {
    position: relative;
  }

  &-title {
    color: $bg-navy;
    position: relative;

    @include fs-min-width($bp-small) {
      padding-right: fs-rem(80px);// offset large icon
    }

    &:after {
      border-left: 1px solid $bg-pink;
      content: '';
      height: fs-rem(72px);
      left: fs-rem(14px);
      margin-top: fs-rem(23px);
      position: absolute;
      transform-origin: 0 0;
      top: 100%;

      @include fs-min-width($bp-medium) {
        @include lineExtend;
        height: fs-rem(300px);
        margin-top: fs-rem(70px);
      }
    }
  }

  &-image {
    display: flex;
    gap: 14px;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    top: 102px;

    @include fs-min-width($bp-medium) {
      flex-direction: column;
      right: -5%;
      top: -110px;
    }

    &-img {
      height: 245px;
      width: 182px;

      @include fs-max-width($bp-medium - 1px) {
        order: 2;
      }

      @include fs-min-width($bp-medium) {
        height: 438px;
        width: 325px;
      }

      img {
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }

    &-subtitle {
      font-size: 16px;
      text-align: right;

      @include fs-max-width($bp-medium - 1px) {
        margin-top: 28px;
        max-width: 142px;
      }
    }
  }

  &-decoration {
    @include fadeSlideInRight;
    height: auto;
    position: absolute;
    z-index: 2;

    &:nth-of-type(1) {
      right: 0;
      top: 18%;
      width: fs-rem(119px);

      @include fs-max-width($bp-small - 1px) {
        display: none;
      }
    }

    &:nth-of-type(2) {
      bottom: 18%;
      left: 50%;
      transform: translateX(200%);
      width: fs-rem(70px);

      @include fs-max-width($bp-small - 1px) {
        display: none;
      }
    }
  }
}
