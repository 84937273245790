//------------------------------------------------------------------------
// Recommedations
//------------------------------------------------------------------------
.Recommedations {
  margin-top: fs-scale-clamp((375px: 60px, $layout-homehero-bp: 80px));
  padding-bottom: fs-scale-clamp((375px: 67px, $layout-homehero-bp: 126px));

  .Conclusion + & {
    margin-top: fs-scale-clamp((375px: 158px, $layout-homehero-bp: 165px));
  }

  &-wrap {
    padding-top: fs-scale-clamp((375px: 73px, $layout-homehero-bp: 93px));
    padding-bottom: fs-scale-clamp((375px: 10px, $layout-homehero-bp: 170px));
  }

  &-supertitle {
    color: $bg-blue;
    margin-bottom: fs-scale-clamp((375px: 70px, $layout-homehero-bp: 80px));
  }
}