//------------------------------------------------------------------------
// Header
//------------------------------------------------------------------------
.Header {
  $selector: &;
  $bp-end-scale: 1366px;
  padding-bottom: fs-rem(26px);
  padding-top: fs-rem(34px);
  position: relative;// for z-index
  transition: background-color 180ms ease;
  z-index: fs-zindex('header');

  &:has(#{$selector}-navToggle[aria-expanded="true"]) {
    background-color: $bg-gray-light;
  }

  &-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: fs-rem($layout-max-width-full);
    z-index: 1;

    .no-js & {
      align-items: flex-start;
    }
  }// end wrap

  &-logo {
    max-width: fs-rem(230px);
    width: 54%;

    @include fs-min-width($layout-nav-bp) {
      max-width: fs-scale-clamp(($layout-nav-bp: 180px, $bp-end-scale: 230px));
    }

    &-link {
      color: var(--theme-text-color, $c-navy-dark);
      display: block;
      transition: color 180ms ease, opacity 180ms ease;

      @include fs-attention {
        opacity: 0.9;

        path {
          fill: currentColor;
        }
      }
    }// end link

    &-image {
      display: block;
      height: auto;
      fill: currentColor;
      width: 100%;

      path {
        transition: fill 180ms ease;
      }
    }// end img
  }// end logo

  &-navToggle {
    $burger-gutter: 2px;
    $burger-height: 6px;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include fs-min-width($layout-nav-bp) {
      display: none;
    }

    // Collapsed
    &[aria-expanded="false"] {
      .is-active-show {
        display: none;
      }

      .is-active-hide {
        display: block;
      }

      @include button-attention {
        .icon {
        }
      }
    }

    // Expanded
    &[aria-expanded="true"] {
      .is-active-show {
        display: block;
      }

      .is-active-hide {
        display: none;
      }

      .icon {
        @include fs-burger-to-cross;

        &:before {
          transform: translateY($burger-gutter + $burger-height) rotate(30deg);
        }

        &:after {
          transform: translateY(-($burger-gutter + $burger-height)) rotate(-30deg);
        }
      }
    }

    .icon {
      @include fs-burger(
        $color: $bg-blue,
        $gutter: $burger-gutter,
        $height: $burger-height,
        $transition-duration: 200ms,
        $width: 31px
      );
      color: inherit;
      display: block;
    }// end icon

    &-label {
      color: $c-navy-dark;
      font-size: fs-rem(14px);
      letter-spacing: 0.1em;
      margin-top: fs-rem(6px);
      text-transform: uppercase;
    }// end label
  }// end navToggle

}// end Header
