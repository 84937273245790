//------------------------------------------------------------------------
// Country score maps
// NOTE: Mapbox JS adds “mapboxgl-map” class on init
//------------------------------------------------------------------------
.mapboxgl-map {
  height: 80vh;
  margin-top: fs-scale-clamp((375px: 80px, $layout-max-width-wide-bp: 100px));;
  max-height: fs-rem(745px);
  width: 100%;
}
