//------------------------------------------------------------------------
// Stylised List
//------------------------------------------------------------------------
.StylisedList {
  $selector: &;
  $bp-medium: 768px;
  color: $bg-navy;

  &.is-blue {
    --accent-color: #{$bg-blue-light};
    --background-color: #{$bg-navy};
  }

  &.is-pink {
    --accent-color: #{$bg-pink};
    --background-color: #{$bg-pink};
  }

  &.is-yellow {
    --accent-color: #{$bg-gold-light};
    --background-color: #{$bg-gold};
  }

  // Note: Used by recommendations.njk
  &--responsive {
    @include fs-min-width($bp-medium) {
      display: grid;
      gap: 55px;
      grid-template-columns: 1fr 1fr;
    }

    #{$selector}-list {
      display: block;
    }
  }

  &-title {
    @include fs-scale(font-size, (360px: 30px, $layout-text-scale-end-bp: 34px));
    border-top: solid 4px var(--accent-color, currentColor);
    font-family: $chiavettieri;
    font-weight: normal;
    line-height: 1.2;
    margin-bottom: 29px;
    padding-top: 29px;
  }

  &-list {
    padding-bottom: fs-scale-clamp((375px: 14px, $layout-homehero-bp: 54px));

    @include fs-min-width($bp-medium) {
      display: grid;
      gap: 32px;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &-item {
    list-style: none;
    margin-bottom: 36px;
    padding-left: 40px;
    position: relative;

    &:before {
      background-color: var(--background-color);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 20px;
    }
  }
}
