//------------------------------------------------------------------------
// Expand/collapse styles
// For use with https://github.com/Threespot/expand-toggle
//------------------------------------------------------------------------

// Use this mixin when applying the “data-expands-height” attribute
@mixin expandable($speed: 500ms, $easing: fs-easing("easeOutCubic")) {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows $speed $easing, visibility 0s linear $speed;
  visibility: hidden;

  &[aria-hidden="false"],
  .no-js & {
    grid-template-rows: 1fr;
    transition: grid-template-rows $speed $easing, visibility 0s linear 0s;
    visibility: visible;
  }

  & > * {
    overflow: hidden;
  }
}
