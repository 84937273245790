//------------------------------------------------------------------------
// Map legend
//------------------------------------------------------------------------
.MapLegend {
  margin-bottom: fs-scale-clamp((375px: 60px, $layout-max-width-wide-bp: 120px));
  margin-top: fs-rem(40px);

  &-title {
    font-size: fs-rem(22px);
    letter-spacing: 1.76px;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
  }// end title

  &-list {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 30px 40px;
    justify-content: center;
    margin-top: fs-rem(20px);
  }// end list

  &-item {
    align-items: center;
    display: flex;
    font-size: fs-rem(20px);
    font-weight: bold;
    letter-spacing: 0.6px;
    line-height: 1.3;

    &:after {
      background-color: var(--bg-color);
      border-radius: 100px;// arbitrary large value
      content: '';
      display: block;
      height: fs-rem(10px);
      margin-left: fs-rem(5px);
      width: fs-rem(23px);
    }
  }// end item
}// end MapLegend
