//------------------------------------------------------------------------
// Key Takeaways
//------------------------------------------------------------------------
.KeyTakeaways {
  $bp-sticky: 1024px;
  $bp-end-scale: $layout-max-width-bp;
  background-image: linear-gradient(to right, $bg-beige, $bg-beige 72%, $bg-default 72%, $bg-default);
  color: $c-green-dark;
  position: relative;
  z-index: 1;

  @include fs-min-width($bp-sticky) {
    background-image: linear-gradient(to right, $bg-beige, $bg-beige 67%, $bg-default 67%, $bg-default);
  }

  &-wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: fs-rem($layout-max-width-full);

    @include fs-min-width($bp-sticky) {
      display: flex;
      justify-content: space-between
    }
  }// end wrap

  &-primary {
    @include fs-min-width($bp-sticky) {
      flex-shrink: 0;
      width: 50%;
    }

    &-wrap {
      @include fs-min-width($bp-sticky) {
        position: sticky;
        top: 0;
      }
    }// end wrap
  }// end content

  // Note: Has “f-supertitle” class
  &-supertitle {
    color: currentColor;
    margin-bottom: fs-scale-clamp((375px: 26px, $bp-end-scale: 51px));
    padding-top: fs-scale-clamp((375px: 81px, $bp-sticky: 107px));
  }// end supertitle

  // Note: Has “f-h2” class
  &-title {
    color: $bg-green-dark;
    line-height: 1;
    margin-bottom: fs-scale-clamp((375px: 48px, $bp-end-scale: 69px));
  }// end title

  &-image {
    background: $bg-pattern-grid-dots;
    padding-bottom: fs-rem(84px);

    &.is-mobile {

      @include fs-min-width($bp-sticky) {
        display: none;
      }
    }

    &.is-desktop {
      margin-left: fs-rem(-$layout-padding-max);

      @include fs-max-width($bp-sticky - 1px) {
        display: none;
      }

      @include fs-min-width($layout-max-width-full-bp) {
        margin-left: calc(-50vw + #{$layout-max-width-full * 0.5});
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &-secondary {
    margin-right: auto;
    max-width: 568px;
  }// end secondary

  &-list {
    margin-bottom: fs-rem(76px);

    @include fs-scale(padding-left, (
      $layout-padding-min-bp: 28px,
      $bp-sticky: 80px
    ));

    @include fs-scale(padding-right, (
      $layout-padding-min-bp: 28px,
      $bp-sticky: 0
    ));

    @include fs-min-width($bp-sticky) {
      margin-bottom: 50vh;
    }
  }

  &-item {
    @include fs-scale(font-size, (375px: 20px, $bp-sticky: 26px));
    font-weight: bold;
    line-height: 1.47;
    list-style: none;
    margin-top: fs-rem(60px);

    @include fs-min-width($bp-sticky) {
      margin-top: 50vh;
    }

    &-svg {
      @include fs-scale(margin-bottom, (375px: 17px, $bp-sticky: 27px));
      display: block;
      fill: $bg-gold;
      transition: fill 300ms ease;
      width: 65px;

      .start-animation & {
        fill: $bg-green;
      }
    }
  }// end item
}
