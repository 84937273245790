//------------------------------------------------------------------------
// Country maps tooltip
//------------------------------------------------------------------------
.mapboxgl-popup {
  $selector: &;
  --bg-color: #{$bg-navy};
  color: $c-blue-light;
  font-family: $body-copy-font;
  max-width: fs-rem(375px) !important;

  // Note: Mapbox generates this close button
  // <button class="mapboxgl-popup-close-button" type="button" aria-label="Close popup" aria-hidden="true">×</button>
  &-close-button {
    color: #fff;
    font-size: fs-rem(24px);
    opacity: 0.7;
    padding: fs-rem(8px);
    transition: opacity 150ms ease-in-out;

    @include fs-attention {
      opacity: 1;
    }
  }// end close-button

  &-tip {}// end tip

  &-anchor {
    &-right {
      #{$selector}-tip {
        border-left-color: var(--bg-color) !important;
      }
    }

    &-left {
      #{$selector}-tip {
        border-right-color: var(--bg-color) !important;
      }
    }

    &-top,
    &-top-left,
    &-top-right {
      #{$selector}-tip {
        border-bottom-color: var(--bg-color) !important;
      }
    }// end top-right

    &-bottom,
    &-bottom-left,
    &-bottom-right {
      #{$selector}-tip {
        border-top-color: var(--bg-color) !important;
      }
    }
  }// end anchor

  &-content {
    @include fs-font-smoothing;
    background-color: var(--bg-color);
    border-radius: 0;// override Mapbox default
    padding: fs-rem(30px 20px);
  }// end content

  &-title {
    font-family: $chiavettieri;
    font-size: fs-rem(26px);
    letter-spacing: 0.3px;
    line-height: 1.07;
    margin-bottom: fs-rem(20px);
  }// end title

  &-score {
    align-items: center;
    display: flex;
    font-size: fs-rem(16px);
    letter-spacing: 1.28px;
    margin-top: fs-rem(10px);
    text-transform: uppercase;

    &-label {
      margin-right: fs-rem(10px);
      padding-right: fs-rem(10px);
      position: relative;

      &:after {
        border-right: 1px solid $bg-blue-border;
        bottom: 0;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
      }
    }// end label
  }// end score

  &-cta {
    --theme-text-color: #fff;
    @include link-richtext;
    display: inline-block;
    font-size: fs-rem(16px);
    font-weight: bold;
    letter-spacing: 0.64px;
    line-height: 1.125;
    margin-top: fs-rem(20px);
  }// end cta
}
