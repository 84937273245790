//------------------------------------------------------------------------
// Tooltips custom styling
//------------------------------------------------------------------------
/* stylelint-disable no-duplicate-selectors */
// NOTE: Move CSS vars to :root so audio player can use them since they
//       share the same styles.
.Tooltip {
  $selector: &;
  $bp-end-scale: 600px;
  $bg-easing: cubic-bezier(0.68, 0.01, 0.49, 1);
  $triangle-size: 30px;
  $triangle-vert-margin: -7px;// gap between triangle and toggle text
  $bg-color: $bg-navy;
  $icon-gutter: 12px;
  $bottom-padding: 30px;
  $hover-underline-width: 3px;
  $drop-shadow: drop-shadow(0 0 12px rgba(#000, 0.25));
  --toggle-icon-width: #{fs-em(19px, 26px)};
  --toggle-left-padding: #{fs-em(6px, 26px)};

  @at-root .no-js [data-tooltip] {
    &:after {
      color: $c-default;
      content: ' (' attr(title) ')';
      display: block;
      font-family: $body-copy-font;
      font-size: fs-rem(16px);
      line-height: $body-copy-line-height;
    }
  }

  &-toggle {
    --icon-color: #{$bg-navy};
    --icon-bg-color: #{rgba($bg-blue-light, 0.5)};
    align-items: center;
    display: inline-block;
    transition: all 150ms ease-in-out;

    // Inside sortable table button
    [data-sort] & {
      --icon-bg-color: transparent;
      --icon-color: #fff;

      // Add outline to icon
      .icon {
        border-color: currentColor;
      }
    }

    @include fs-all-states {
      color: currentColor;
    }

    // Tooltip expanded
    &:has(+ .is-active) {
      --icon-color: #fff;
      --icon-bg-color: #{$bg-navy};
    }

    // Hover styles
    &:hover,
    [data-whatinput="keyboard"] &:focus {
      --icon-bg-color: transparent;
      --icon-color: #{$bg-navy};

      // Add outline to icon
      .icon {
        border-color: currentColor;
      }
    }

    // Inside sortable table button
    [data-sort] &:hover,
    [data-whatinput="keyboard"] [data-sort] &:focus {
      --icon-bg-color: #{rgba(#fff, 0.25)};
      --icon-color: #fff;
    }

    .icon {
      border: 1px solid transparent;
      border-radius: 50%;
      height: fs-rem(26px);
      transition: all 150ms ease-in-out;
      width: fs-rem(25px);

      // Hide icons when JS is disabled
      @at-root .no-js [data-tooltip] .icon {
        display: none;
      }
    }
  }// end toggle

  &-content {
    margin-bottom: fs-rem(($triangle-size * 0.5) + $triangle-vert-margin);// account for triangle

    &[data-align="full"],
    &.l-fullwidth {
      margin-top: fs-rem(-($triangle-size * 0.5) - $triangle-vert-margin);// account for triangle
    }
  }// end content

  &-inner {
    @include fs-clearfix;
    @include fs-font-smoothing;
    background-color: $bg-color;
    color: #fff;
    filter: $drop-shadow;
    font-family: $body-copy-font;
    font-size: fs-rem(16px);
    line-height: $body-copy-line-height;
    padding: fs-rem(20px);

    @media print {
      font-weight: normal !important;
      line-height: inherit;
      padding: 0;
    }

    a {
      text-decoration: underline;

      @include fs-static {
        color: currentColor;
      }

      @include fs-attention {
        text-decoration: none;
      }
    }

    // Triangle
    &:before {
      @include fs-triangle($triangle-size, $bg-color, down);

      [data-align*="bottom"] &,
      .align-bottom & {
        @include fs-triangle($triangle-size, $bg-color, up);
      }
    } // end triangle
  }// end inner

  &-description {
    display: block;
  }// end description

}// end [data-tooltip]
