// -------------------------------------------------------------------------
// Link Styles
// -------------------------------------------------------------------------
// Base link styles, excluding color
@mixin link-base {
  transition: color 150ms ease-in-out;

  @include fs-all-states {
    text-decoration: none;
  }
}

@mixin link-default($color: $c-blue) {
  @include fs-all-states {
    // Optional: Use CSS var to make it easier to override colors
    color: var(--theme-text-color, $color);
  }

  @include fs-attention {
    text-decoration: underline;
  }
}

@mixin link-add-icon($icon-name, $icon-width) {
  $arrow-icon-hover-shift: 4px;

  @supports (mask-size: contain) {
    // Use SVG mask to add icon so it can inherit the color
    // https://jsfiddle.net/34rmz5k7/2/
    // 1. Must set “display: inline;” to prevent icon from wrapping by itself
    // 2. Use padding instead of width to prevent icon from wrapping by itself
    &:after {
      background: currentColor;// this is the icon color
      content: '';
      display: inline;// [1]
      font-size: inherit;
      height: 1em;
      margin-left: 0.2em;
      margin-right: 0.2em;
      mask-image: fs-svg-url($icon-name), linear-gradient(transparent, transparent);
      mask-position: left center;
      mask-repeat: no-repeat;
      mask-size: $icon-width;
      padding-left: $icon-width;// [2]
      transition: mask-position 180ms ease;
      vertical-align: middle;

      @if $icon-name == 'arrowRight' {
        margin-right: 0;
        padding-left: calc(#{$icon-width} + #{$arrow-icon-hover-shift});
      }

      // Don’t animate the download icon
      @if $icon-name == 'download' {
        margin-right: 0.2em;
        padding-left: $icon-width;
      }
    }
  }

  // Arrow hover styles
  @if $icon-name == 'arrowRight' {
    @include fs-attention {
      &:after {
        mask-position: $arrow-icon-hover-shift center;
      }
    }
  }

  // Don’t animate the download icon
  @if $icon-name == 'download' {
    @include fs-attention {
      &:after {
        mask-position: left center;
      }
    }
  }
}

// Append an icon based on the URL
@mixin link-icon() {
  // Arrows for regular internal links
  &:where(:not([href^="http"])) {
    @include link-add-icon(
      $icon-name: 'arrowRight',
      $icon-width: fs-rem(11px),
    );
  }

  // External links
  // Use :where() to keep specificity low so we can override when external link is also a download
  &:where([href^="http"]:not([href*="lndo.site"], [href*="//localhost"], [href*="pantheonsite.io"], [href*="reportcards.malala.org"])) {
    @include link-add-icon(
      $icon-name: 'external',
      $icon-width: fs-rem(11px),
    );
  }

  // Use download icon for internal links to files in media library
  // Note: We’re using `href*=` instead of `href$=` to account for
  //       external links with query params. Technically, these
  //       edge cases would incorrectly be styled as downloads
  //       but they seem highly unlikely:
  //       https://example.org/not-a-file?foo=bar.pdf
  //       https://www.pdf.example.org/not-a-file
  &:where([href*=".pdf"], [href*=".doc"], [href*=".docx"], [href*=".xls"], [href*=".xlsb"], [href*=".xlsm"], [href*=".xlsx"], [href*=".ppt"], [href*=".pptm"], [href*=".pptx"]) {
    @include link-add-icon(
      $icon-name: 'download',
      $icon-width: fs-rem(11px),
    );
  }
}

// Custom named link styles
//---------------------------------
@mixin link-richtext() {
  @include link-default;
  @include link-icon;
}
