//------------------------------------------------------------------------
// Background color classes
//
// sass-lint:disable no-important
// scss-lint:disable ImportantRule
//------------------------------------------------------------------------
.bg {
  &-default {
    background-color: $bg-default;
  }

  &-beige {
    background-color: #f3ecdf;
  }

  // Theme colors
  //---------------------------------
  @each $name, $color in $theme-colors {
    &-#{$name} {
      --theme-color: #{$color};
      // Use dark text on light backgrounds
      @if index(('blue', 'green', 'navy'), $name) {
        --theme-text-color: #fff;
        @include fs-font-smoothing;
      }
      background-color: var(--theme-color);
      color: var(--theme-text-color);
    }
  }
} // end bg
