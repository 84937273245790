//------------------------------------------------------------------------
// Homepage Hero
//------------------------------------------------------------------------
.HomeHero {
  $bp-wide: $layout-homehero-bp;
  $bp-end-scale: 1366px;
  $max-width-plus-padding: $layout-max-width-full + ($layout-padding-max * 2);
  color: $c-blue;
  margin-top: fs-rem(22px);
  position: relative;
  z-index: 2;

  @include fs-min-width($bp-wide) {
    margin-top: fs-scale-clamp(($bp-wide: 22px, $bp-end-scale: 85px));
  }

  &-wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: fs-rem($layout-max-width-full);
    position: relative;
  }

  &-top {
    @include fs-min-width($bp-wide) {
      position: relative;
    }

    &-wrap {
      @include fs-min-width($bp-wide) {
        width: 70%;// magic number to match design
      }
    }// end wrap
  }// end top

  &-title {
    font-family: $chiavettieri;
    font-size: fs-scale-clamp((375px: 57px, $bp-end-scale: 140px));
    font-weight: bold;
    line-height: 0.96;
    position: relative;
    z-index: 2;// above decorations

    @include fs-min-width($bp-wide) {
      margin-bottom: 0;
    }
  }

  &-description {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 10px 20px;
    margin-top: fs-scale-clamp((375px: 17px, $bp-end-scale: 24px));
  }

  &-subtitle {
    font-size: fs-scale-clamp((375px: 16px, $bp-end-scale: 26px));
    letter-spacing: fs-scale-clamp((320px: 0.5px, $bp-end-scale: 2.6px));
    line-height: 1.3;
    text-transform: uppercase;
  }// end subtitle

  &-updated {
    background-color: $bg-beige;
    border-radius: 100px;// arbitrary large value
    font-size: fs-scale-clamp((375px: 14px, $bp-end-scale: 16px));
    letter-spacing: fs-scale-clamp((320px: 1.4px, $bp-end-scale: 1.6px));
    line-height: 1;
    padding: fs-rem(8px 15px);
    text-transform: uppercase;
  }// end updated

  &-border {
    padding-left: fs-scale-clamp((
      $layout-padding-min-bp: $layout-padding-min,
      $layout-padding-max-bp: $layout-padding-max
    ));
    margin-top: fs-rem(38px);

    @include fs-min-width($bp-wide) {
      margin-bottom: fs-rem(-136px);
      margin-top: fs-scale-clamp(($bp-wide: 80px, $bp-end-scale: 124px));
    }

    @include fs-min-width($max-width-plus-padding) {
      padding-left: calc(50vw - #{$layout-max-width-full * 0.5});
    }

    &-wrap {
      border-left: 1px solid $bg-red;
      padding-left: fs-scale-clamp((375px: 27px, $bp-end-scale: 91px));

      @include fs-min-width($bp-wide) {
        padding-bottom: fs-rem(46px);
      }
    }// end wrap
  }

  &-decorations {
    &.is-mobile {
      align-items: flex-start;
      display: flex;
      justify-content: flex-end;
      margin-bottom: fs-rem(20px);

      @include fs-min-width($bp-wide) {
        display: none;
      }
    }

    &.is-desktop {
      @include fadeSlideInRight;
      align-items: flex-start;
      bottom: 0;
      display: flex;
      position: absolute;
      right: 0;

      @include fs-max-width($bp-wide - 1px) {
        display: none;
      }

      @include fs-min-width($max-width-plus-padding) {
        margin-right: calc(-50vw + #{$layout-max-width-full * 0.5});
      }
    }
  }// end decorations

  &-flower {
    display: block;
    height: auto;
    width: fs-rem(57px);

    @include fs-min-width($bp-wide) {
      width: fs-scale-clamp(($bp-wide: 100px, $bp-end-scale: 219px));
    }
  }// end flower

  &-doubleFlower {
    display: block;
    height: auto;

    @include fs-max-width($bp-wide - 1px) {
      margin-left: fs-rem(19px);
      transform: translateY(-100%);
      width: fs-rem(32px);
    }

    @include fs-min-width($bp-wide) {
      margin-top: fs-rem(-37px);
      transform: translateY(-100%);
      width: fs-scale-clamp(($bp-wide: 66px, $bp-end-scale: 86px));
    }
  }// end doubleFlower

  &-image {
    display: block;
    position: relative;

    img {
      display: block;
      position: relative;
      width: 100%;
      z-index: 1;
    }

    &-decoration {
      height: auto;
      position: absolute;
      z-index: 2;

      &:nth-of-type(1) {
        bottom: 0;
        left: 49.38%;// 160/324
        transform: translateY(50%);
        width: fs-scale-clamp((375px: 20px, $bp-end-scale: 50px));

        @include fs-min-width($bp-wide) {
          left: 65.28%;// 754/1155
        }
      }

      &:nth-of-type(2) {
        left: 0;
        top: 54.85%;// 379/691
        transform: translateX(-50%);
        width: fs-rem(50px);

        @include fs-max-width($bp-wide - 1px) {
          display: none;
        }
      }
    }
  }
}
