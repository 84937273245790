//------------------------------------------------------------------------
// Web fonts
// Note: Only woff and woff2 are required for modern browsers
// https://css-tricks.com/snippets/css/using-font-face/#article-header-id-1
//
// In the future, we could consider not loading fonts if “data-saver” mode is enabled
// https://css-tricks.com/responsible-conditional-loading/
// https://caniuse.com/mdn-css_at-rules_media_prefers-reduced-data
//------------------------------------------------------------------------

// We’re using “font-display: fallback” as a compromise between
// performance and minimizing the chances of a FOIT or FOUC.
//
//   “The browser will hide the text for about 100ms and, if the font
//   has not yet been downloaded, will use the fallback text. It will
//   swap to the new font after it is downloaded, but only during a
//   short swap period (probably 3 seconds).”
//   https://css-tricks.com/almanac/properties/f/font-display/
//
$font-display: fallback;

// Custom emoji font to standardize appearance across platforms
// https://www.client9.com/css-color-emoji-stack/
// https://nolanlawson.com/2022/04/08/the-struggle-of-using-native-emoji-on-the-web/
@font-face {
  font-family: "color-emoji";
  src: local("Apple Color Emoji"),
       local("Twemoji Mozilla"),
       local("Segoe UI Emoji"),
       local("Segoe UI Symbol"),
       local("Noto Color Emoji"),
       local("EmojiOne Color"),
       local("Android Emoji");
}

// Lato
// https://fonts.google.com/specimen/Lato
//---------------------------------
// Note: This weight is only used for numbers so we reduced the character set
// https://fonts.googleapis.com/css2?family=Lato:wght@300&text=1234567890
// https://developers.google.com/fonts/docs/css2#optimizing_your_font_requests
@font-face {
  font-display: $font-display;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/lato/lato-v24-latin-300-numbers.woff2") format("woff2"),
       url("../fonts/lato/lato-v24-latin-300-numbers.woff") format("woff");
}

@font-face {
  font-display: $font-display;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/lato/lato-v24-latin-regular.woff2") format("woff2"),
       url("../fonts/lato/lato-v24-latin-regular.woff") format("woff");
}

@font-face {
  font-display: $font-display;
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/lato/lato-v24-latin-regular-italic.woff2") format("woff2"),
       url("../fonts/lato/lato-v24-latin-regular-italic.woff") format("woff");
}

@font-face {
  font-display: $font-display;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/lato/lato-v24-latin-700.woff2") format("woff2"),
       url("../fonts/lato/lato-v24-latin-700.woff") format("woff");
}

@font-face {
  font-display: $font-display;
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/lato/lato-v24-latin-700-italic.woff2") format("woff2"),
       url("../fonts/lato/lato-v24-latin-700-italic.woff") format("woff");
}

// Note: We don’t need italic for this weight since it’s only used for numbers and nav toggles
@font-face {
  font-display: $font-display;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/lato/lato-v24-latin-900.woff2") format("woff2"),
       url("../fonts/lato/lato-v24-latin-900.woff") format("woff");
}

// Chiavettieri
// https://fonts.adobe.com/fonts/chiavettieri
// https://use.typekit.net/mdf0mpr.css
/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * chiavettieri:
 *   - http://typekit.com/eulas/00000000000000007735acc6
 *   - http://typekit.com/eulas/00000000000000007735acc2
 *
 * © 2009-2023 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2023-06-07 20:18:03 UTC"}*/

@font-face {
  font-display: $font-display;
  font-family: "Chiavettieri";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/chiavettieri/chiavettieri-regular.woff2") format("woff2");
}

@font-face {
  font-display: $font-display;
  font-family: "Chiavettieri";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/chiavettieri/chiavettieri-regular-italic.woff2") format("woff2");
}

@font-face {
  font-display: $font-display;
  font-family: "Chiavettieri";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/chiavettieri/chiavettieri-700.woff2") format("woff2");
}
