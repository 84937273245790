// Animation helper mixin with sensible defaults
@mixin animation(
  $name,
  $duration: 1s,
  $timing-fn: ease,
  $delay: 0s,
  $count: 1,
  $direction: normal,
  $fill: both
) {
  // animation-delay: $delay;
  // animation-direction: $direction;
  // animation-duration: $duration;
  // animation-fill-mode: $fill;
  // animation-iteration-count: $count;
  // animation-name: $name;
  // animation-timing-function: $timing-fn;
  animation: $name $duration $timing-fn $delay $count $direction $fill;
}

// For desktop home hero decorations
@keyframes fadeSlideInRight {
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@mixin fadeSlideInRight(
  $duration: 500ms,
  $timing-fn: fs-easing('easeOutCubic'),
  $delay: 600ms,
  $count: 1,
  $direction: normal,
  $fill: both
) {
  @include animation(fadeSlideInRight, $duration, $timing-fn, $delay, $count, $direction, $fill);
}

// Data point dots
@keyframes dotExpand {
  100% {
    border-radius: 50%;
    transform: scaleY(1);
  }
}

@mixin dotExpand(
  $duration: 200ms,
  $timing-fn: ease,
  $delay: 600ms,
  $count: 1,
  $direction: normal,
  $fill: both
) {
  @include animation(dotExpand, $duration, $timing-fn, $delay, $count, $direction, $fill);
}

// Icon rotate and fade (for country header)
@keyframes fadeInRotate {
  0% {
    opacity: 0.3;
    transform: translateX(-20px) rotate(-45deg);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@mixin fadeInRotate(
  $duration: 300ms,
  $timing-fn: fs-easing('easeOutCubic'),
  $delay: 600ms,
  $count: 1,
  $direction: normal,
  $fill: both
) {
  @include animation(fadeInRotate, $duration, $timing-fn, $delay, $count, $direction, $fill);
}

// Extend line on default pages
@keyframes lineExtend {
  0% {
    transform: scaleY(0.1567)// 47 / 300;
  }
  100% {
    transform: none;
  }
}

@mixin lineExtend(
  $duration: 300ms,
  $timing-fn: fs-easing('easeOutCubic'),
  $delay: 600ms,
  $count: 1,
  $direction: normal,
  $fill: both
) {
  @include animation(lineExtend, $duration, $timing-fn, $delay, $count, $direction, $fill);
}
