//------------------------------------------------------------------------
// Objective
//------------------------------------------------------------------------
.Objective {
  padding-top: fs-rem(54px);
  position: relative;
  z-index: 1;

  @include fs-min-width($layout-homehero-bp) {
    padding-top: fs-rem(234px);
  }

  &-supertitle {
    @include fs-scale(margin-bottom, (375px: 40px, $layout-homehero-bp: 61px));
    color: $bg-gold;

    .js & {
      opacity: 0;
      transform: scale(1.3);
      transform-origin: 0 0;
      transition: opacity 250ms ease, transform 400ms ease-out;

      &.start-animation {
        opacity: 1;
        transform: none;
      }

      @media print {
        opacity: 1;
        transform: none;
      }
    }
  }

  &-border {
    border-left: $bg-gold-light 1px solid;
    padding-left: fs-rem(30px);

    @include fs-min-width($layout-homehero-bp) {
      padding-left: fs-rem(94px);
    }
  }

  &-title {
    color: $bg-beige !important;
    margin-bottom: fs-rem(22px);
    max-width: 1019px;
  }

  &-description {
    @include fs-scale(padding-bottom, (375px: 60px, $layout-homehero-bp: 90px));
    max-width: 780px;
  }

  &-cards {
    @include fs-min-width($layout-homehero-bp) {
      display: flex;
      gap: 1px;
    }
  }

  &-card {
    background: $bg-navy-dark;
    border-top: 9px solid $bg-blue-border;
    margin-bottom: fs-rem(5px);
    padding: 24px 20px;

    @include fs-min-width($layout-homehero-bp) {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      padding: 44px 35px;
      width: 50%;
    }

    &-title {
      @include fs-scale(margin-bottom, (375px: 10px, $layout-homehero-bp: 30px));
      @include h3;
      color: $bg-beige !important;
      line-height: 1 !important;
    }

    &-description {
      margin-bottom: 30px;
    }

    &-cta {
      @include fs-min-width($layout-homehero-bp) {
        margin-top: auto;
      }
    }
  }
}
