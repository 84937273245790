//------------------------------------------------------------------------
// Global left/right padding mixin, used by _layout-helpers.scss
//------------------------------------------------------------------------
// Used by image slides
@mixin default-padding() {
  @include fs-scale(padding-left padding-right, (
    $layout-padding-min-bp: $layout-padding-min,
    $layout-padding-max-bp: $layout-padding-max
  ));

  @media print {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

// @mixin container-vert-padding() {
//   @include fs-scale(padding-bottom padding-top, (
//     $layout-padding-min-bp: 32px,
//     $layout-padding-max-bp: 62px
//   ));
// }
