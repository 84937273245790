//------------------------------------------------------------------------
// Tooltips base styles (don’t edit unless you know what you’re doing)
//------------------------------------------------------------------------
/* stylelint-disable no-duplicate-selectors */

.Tooltip {
  $selector: &;
  $gutter: 10px;
  $triangle-gutter: 16px;
  $speed: 200ms;
  $max-width: 358px;
  $max-width-wide: 728px;
  display: inline-block;
  position: relative;
  transition: all 150ms ease-in-out;
  vertical-align: middle;

  // Need to disable relative positioning to make full width since
  // we need left/right relative to viewport, not tooltip toggle.
  &.is-fullwidth {
    position: static;
  }// end is-fullwidth

  // Uncomment to show definition when JS is disabled
  // .no-js & {
  //   &:after {
  //     content: ' (' attr(title) ')';
  //     display: inline;
  //   }
  // }

  &-toggle {
    position: relative;
    z-index: 2;
  }// end toggle

  // All positioning styles shoud be applied to this element
  // (but not tooltip theme styles)
  &-content {
    bottom: 100%;
    left: 50%;
    position: absolute;
    text-align: left;
    transform: translateX(-50%);
    width: calc(100vw - #{$gutter * 2});
    z-index: -1;// below toggle

    @media print {
      font-size: 0.85em !important;
      position: static;
      transform: none;
      width: auto;

      &:before {
        content: ' (';
      }

      &:after {
        content: ')';
      }
    }

    @supports (width: max-content) {
      max-width: calc(100vw - #{$gutter * 2});
      width: max-content;
    }

    @include fs-min-width($max-width + ($gutter * 2)) {
      max-width: fs-rem($max-width);
    }

    &.is-active {
      z-index: 3;// above toggle
    }

    // Left alignment
    &[data-align*="left"] {
      left: 0;
      right: auto;
      transform: translateX(0);

      // Triangle
      #{$selector}-inner {
        &:before {
          left: $triangle-gutter;
          transform: none;
        }
      }
    }

    // Right alignment
    &[data-align*="right"] {
      left: auto;
      right: 0;
      transform: translateX(0);

      // Triangle
      #{$selector}-inner {
        &:before {
          left: auto;
          right: $triangle-gutter;
          transform: none;
        }
      }
    }

    // Bottom alignment
    &[data-align*="bottom"],
    .align-bottom & {
      bottom: auto;
      top: 100%;
      transform: translate(-50%, 1em);// ensure tooltip doesn’t overlap the toggle
    }

    // Full width
    &[data-align*="full"],
    &.l-fullwidth {
      bottom: auto;
      left: 0;
      max-width: none !important;
      top: auto;
      transform: translateY(1.25em);
      width: 100% !important;

      // Center the tooltip once max-width is applied
      @include fs-min-width($max-width + ($gutter * 2)) {
        left: 50%;
        right: auto;
        transform: translate(-50%, 1.25em);
      }

      // Top alignment
      &[data-align*="top"] {
        margin-top: -0.5em;
        transform: translateY(-100%);

        @include fs-min-width($max-width + ($gutter * 2)) {
          transform: translate(-50%, -100%);
        }
      }

      // Hide triangle since it may not line up correctly
      #{$selector}-inner {
        &:before {
          display: none;
        }
      }
    }// end full
  }// end content

  // Do not apply positioning styles to this element
  &-inner {
    display: block;
    opacity: 0;
    position: relative;
    transform: translateY(10%);
    transition: visibility 0s linear $speed,
                opacity $speed linear,
                transform $speed ease-in-out;
    visibility: hidden;// hide from screen readers and keyboards until active
    z-index: 1;// above toggle

    &[aria-hidden="false"] {
      opacity: 1;
      transform: translateY(0);
      transition: visibility 0s linear 0s,
                  opacity $speed,
                  transform $speed;
      visibility: visible;
    }

    @media print {
      display: inline;
      font-size: 1em !important;
      opacity: 1;
      transform: none;
      visibility: visible;
    }

    // Base triangle styles
    &:before {
      // Triangle size and color is defined in “_tooltips-custom.scss”
      // e.g. @include fs-triangle($triangle-size, $bg-color, down);
      content: '';
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translateX(-50%);
      transition: opacity 150ms ease-in-out;

      [data-align*="bottom"] &,
      .align-bottom & {
        bottom: 100%;
        top: auto;
      }

      @media print {
        display: none;
      }
    }// end triangle
  }// end inner

}// end data-tooltip
