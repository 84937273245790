// -------------------------------------------------------------------------
// Color variables
//
// Use variable names that describe the color. Avoid additional abstraction
// unless absolutely necessary (e.g. themeing)
// http://bensmithett.com/stop-using-so-many-sass-variables/
//
// Can’t think of another name for a similar color? Try this site:
// http://chir.ag/projects/name-that-color/
//
// Note: We’re using the “postcss-custom-properties” plugin to add
//       fallbacks for browsers that don’t support CSS vars.
//       https://caniuse.com/css-variables
// -------------------------------------------------------------------------

// Background colors
//---------------------------------
$bg-beige: #efe2c2;
$bg-blue-light: #a7b2d5;
$bg-blue-lighter: #e5e6ec;// rgba($bg-blue-light, 0.2)
$bg-blue-border: #6579b6;
$bg-blue: #233f96;
$bg-gold: #d8b767;
$bg-gold-dark: #a0864c;
$bg-gold-light: #e4cd95;
$bg-gray: #d4cdca;// table border
$bg-gray-light: #ece8e7;
$bg-green: #0a7b6d;
$bg-green-dark: #064f46;
$bg-green-light: #0da794;
$bg-green-lighter: #9edcd4;
$bg-green-yellow: #bbc99d;
$bg-navy: #1a2e6e;
$bg-navy-dark: #111e47;
$bg-offwhite: #f5f3f2;
$bg-orange: #e2904f;// taken from static map comp
$bg-pink: #f39473;
$bg-red: #ee6536;
$bg-red-light: #f8c1af;
$bg-teal-light: #56c2b4;

// Text colors
//---------------------------------
$c-black: #413f3d;
$c-blue: $bg-blue;
$c-blue-light: $bg-blue-light;
$c-gray-light: $bg-gray-light;
$c-green-dark: $bg-green-dark;
$c-navy: $bg-navy;
$c-navy-dark: $bg-navy-dark;
$c-red: #eb0c00;

// Theme colors (used in _background-helpers.scss)
//---------------------------------
$theme-colors: (
  'blue': $bg-blue,
  'green': $bg-green,
  'light-blue': $bg-blue-lighter,
  'light-gray': $bg-gray-light,
  'navy': $bg-navy,
);

// Data colors (used by _data-color-helpers.scss)
// Note: Use number keys instead of colors to make
//       it easy to change/add colors in the future.
//---------------------------------
$data-colors: (
  '5': $bg-red,
  '4': $bg-gold-dark,
  '3': $bg-gold,
  '2': $bg-green-yellow,
  '1': $bg-teal-light,
);

// Default colors
//---------------------------------
$bg-default: $bg-offwhite;
$c-default: $c-black;

// Note: We’re CSS variables to make it easier to switch color themes.
//       We’re using the “postcss-custom-properties” plugin to add
//       fallbacks for browsers that don’t support CSS vars.
//       https://caniuse.com/css-variables
// $bg-default: var(--bg-default);
// $c-default: var(--c-default);

// Backgrounds
//---------------------------------
$bg-pattern-circles: 0 0 / 50px auto url('../images/patterns/circles.svg') $bg-red;
$bg-pattern-flowers: 0 52px / 100px auto url('../images/patterns/flower.svg') $bg-navy-dark;
$bg-pattern-grid-dots: 21px 21px / 60px auto url('../images/patterns/grid-dots.svg') $bg-gold;
$bg-pattern-holly: -45px -9px / 90px auto url("../images/patterns/holly.svg") $bg-green-dark;
