// -------------------------------------------------------------------------
// Font variables
//
// Note: Quotes arn’t necessary for most font names https://mothereff.in/font-family
//
// Note: “color-emoji” is a custom font defined in _fonts.scss
//       See https://www.client9.com/css-color-emoji-stack/ for details.
//------------------------------------------------------------------------

// Fallback fonts
//---------------------------------
$sans-serif: system-ui, Segoe UI, sans-serif, color-emoji;
$serif: Georgia, Times New Roman, Times, serif, color-emoji;
$monospace: Consolas, Menlo, Monaco, monospace, color-emoji;

// Custom web fonts
//---------------------------------
$lato: Lato, $sans-serif;
$lato-light: 300;// only used for numbers in country headers
$lato-black: 900;

$chiavettieri: Chiavettieri, $serif;

// Body copy vars
//---------------------------------
$body-copy-font: $lato;
$body-copy-min-size: 18px;
$body-copy-max-size: 20px;
$body-copy-line-height: 1.5;
