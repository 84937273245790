// -------------------------------------------------------------------------
// Button Styles
// -------------------------------------------------------------------------
@mixin button-reset {
  background-color: transparent;
  background-image: none; // for Firefox mobile
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  text-align: center;
  transition: background-color 150ms ease-in-out,
              background-position 150ms ease,
              border 150ms ease-in-out,
              color 150ms ease-in-out;
}

@mixin button-solid() {
  @include button-reset;
  @include fs-font-smoothing;
  background-color: var(--button-bg-color, $bg-green);
  border-left: 7px solid var(--button-border-color, $bg-green-light);
  color: var(--button-color, #fff);
  font-size: fs-rem(16px);
  font-weight: bold;
  letter-spacing: 0.04em;
  line-height: 1;
  padding: fs-rem(13px 11px);
  text-transform: uppercase;

  @include button-attention {
    background-color: var(--button-hover-bg-color, $bg-green-dark);
    border-left-color: var(--button-hover-border-color, $bg-green);
    color: var(--button-hover-color, $c-gray-light);
  }
}

@mixin button-icon($icon-width: 11px, $icon-hover-shift: 4px) {
  align-items: center;
  display: inline-flex;
  padding-right: fs-rem(13px - $icon-hover-shift);

  @include button-attention {
    &:after {
      mask-position: $icon-hover-shift center;
    }
  }

  // Add arrow icon in modern browsers
  // https://caniuse.com/mdn-css_properties_mask-size
  @supports (mask-size: contain) {
    // Use SVG mask to add icon so it can inherit the color
    // https://jsfiddle.net/34rmz5k7/2/
    // 1. Must set “display: inline;” to prevent icon from wrapping by itself
    // 2. Use padding instead of width to prevent icon from wrapping by itself
    &:after {
      background: currentColor;// this is the icon color
      content: '';
      display: inline;// [1]
      flex-shrink: 0;
      font-size: inherit;
      height: 1em;
      margin-left: fs-rem(5px);
      mask-image: fs-svg-url('arrowRight'), linear-gradient(transparent, transparent);
      mask-position: left center;
      mask-repeat: no-repeat;
      mask-size: var(--icon-width, $icon-width);
      padding-left: var(--icon-width, $icon-width);// [2]
      padding-right: fs-rem($icon-hover-shift);
      transition: mask-position 150ms ease-in-out;
      vertical-align: middle;
    }

    // Download links
    &:where([href*=".pdf"], [href*=".doc"], [href*=".docx"], [href*=".xls"], [href*=".xlsb"], [href*=".xlsm"], [href*=".xlsx"], [href*=".ppt"], [href*=".pptm"], [href*=".pptx"]) {
      &:after {
        mask-image: fs-svg-url('download'), linear-gradient(transparent, transparent);
      }

      // Don’t animate the icon on hover
      @include button-attention {
        &:after {
          mask-position: left center;
        }
      }
    }
  }
}
