.ReportCardHeader {
  $bp-medium: 500px;
  $bp-large: 765px;
  $bp-end-scale: 1200px;
  $title-max-width: 800px;
  $icon-1-min-width: 60px;
  $icon-1-max-width: 126px;
  $icon-1-col-gap: 30px;
  $icon-2-min-width: 30px;
  $icon-2-max-width: 57px;
  $icon-3-min-width: 50px;
  $icon-3-max-width: 90px;
  @include fs-scale(padding-top, (320px: 28px, $bp-medium: 50px));

  &-title {
    @include fs-scale(margin-bottom, (320px: 10px, $bp-medium: 33px));
    font-size: fs-scale-clamp((320px: 35px, $layout-max-width: 100px));
    padding-right: fs-scale-clamp((
      375px: $icon-1-min-width + $icon-1-col-gap,
      $title-max-width: $icon-1-max-width + $icon-1-col-gap
    ));
    position: relative;// for icons

    @include fs-max-width(319px) {
      padding-right: 0;
    }

    @include fs-min-width((2 * $layout-padding-max) + $title-max-width + $icon-1-max-width + $icon-1-col-gap) {
      padding-right: calc(100% - #{$title-max-width});
    }
  }

  &-border {
    padding-left: fs-rem(30px);
    padding-top: 17px;
    position: relative;

    @include fs-min-width($bp-medium) {
      border-left: $bg-gold 1px solid;
      padding-left: fs-rem(94px);
      padding-top: 44px;
    }
  }

  &-description,
  &-optional {
    margin-left: auto;
    max-width: 692px;
  }

  &-description {
    @include fs-scale(padding-bottom, (320px: 30px, $bp-medium: 50px));

    @include fs-max-width($bp-medium - 1px) {
      &::after {
        background-color: $bg-gold;
        content: "";
        height: 140px;
        left: 0;
        position: absolute;
        top: 0;
        width: 1px;
      }
    }
  }

  &-optional {
    @include fs-scale(padding-top, (320px: 30px, $bp-medium: 50px));
    border-top: $bg-red 1px solid;
  }

  &-svg {
    // Large title icon
    &-1 {
      @include fadeSlideInRight;
      position: absolute;
      right: 0;
      top: 0;
      width: fs-scale-clamp((375px: $icon-1-min-width, $title-max-width: $icon-1-max-width));
      z-index: -1;

      @include fs-max-width(319px) {
        display: none;
      }
    }

    // Smaller title icon
    &-2 {
      @include fadeSlideInRight;
      position: absolute;
      right: 13%;
      top: min(100%, #{fs-rem(150px)});
      width: fs-scale-clamp((375px: $icon-2-min-width, $title-max-width: $icon-2-max-width));

      @include fs-max-width(319px) {
        display: none;
      }

      @include fs-min-width($bp-medium) {
        right: 0;
      }

      @include fs-min-width($layout-max-width-wide-bp) {
        right: calc(-50vw + #{($layout-max-width-wide * 0.5)});
      }
    }

    // Icon to left of description in desktop layout
    &-3 {
      @include fadeSlideInRight;
      left: fs-scale-clamp((960px: 60px, $bp-end-scale: 147px));
      position: absolute;
      top: 50%;
      width: fs-scale-clamp((960px: $icon-3-min-width, $bp-end-scale: $icon-3-max-width));

      @include fs-max-width(959px) {
        display: none;
      }
    }
  }
}
