//------------------------------------------------------------------------
// Rich text heading styles
//
// Note: Be aware that “text-rendering: optimizeLegibility” can sometimes
//       result in edge-case issues. Make sure to test if using it.
//------------------------------------------------------------------------
@mixin h2($color: $c-navy-dark, $include-margin: false) {
  @include fs-scale(font-size, (360px: 28px, $layout-text-scale-end-bp: 64px));
  color: var(--theme-text-color, $color);
  font-family: $chiavettieri;
  font-weight: normal;
  line-height: 1.2;

  @if $include-margin {
    margin-bottom: fs-rem(20px);
    margin-top: fs-rem(60px);
  }
}

@mixin h3($color: $c-navy-dark, $include-margin: false) {
  @include fs-scale(font-size, (360px: 30px, $layout-text-scale-end-bp: 44px));
  color: var(--theme-text-color, $color);
  font-family: $chiavettieri;
  font-weight: normal;
  line-height: 1.2;

  @if $include-margin {
    margin-bottom: fs-rem(20px);
    margin-top: fs-rem(60px);
  }
}

@mixin h4($color: $c-navy-dark, $include-margin: false) {
  @include fs-scale(font-size, (360px: 26px, $layout-text-scale-end-bp: 30px));
  color: var(--theme-text-color, $color);
  font-weight: bold;
  letter-spacing: 0.03em;
  line-height: 1.2;

  @if $include-margin {
    margin-bottom: fs-rem(16px);
    margin-top: fs-rem(60px);
  }
}

@mixin h5($color: $c-navy-dark, $include-margin: false) {
  @include fs-scale(font-size, (360px: 24px, $layout-text-scale-end-bp: 28px));
  color: var(--theme-text-color, $color);
  font-weight: bold;
  letter-spacing: 0.03em;
  line-height: 1.2;

  @if $include-margin {
    margin-bottom: fs-rem(16px);
    margin-top: fs-rem(60px);
  }
}

@mixin h6($color: $c-navy-dark, $include-margin: false) {
  @include fs-scale(font-size, (360px: 18px, $layout-text-scale-end-bp: 22px));
  color: var(--theme-text-color, $color);
  font-weight: normal;
  letter-spacing: 0.08em;
  line-height: 1.2;
  text-transform: uppercase;

  @if $include-margin {
    margin-bottom: fs-rem(14px);
    margin-top: fs-rem(60px);
  }
}
