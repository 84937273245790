//------------------------------------------------------------------------
// Styles for custom headings and labels used throughout the project.
//
// Each of these should also be turned into a helper class in _font-helpers.scss
//
// For rich text heading styles (e.g. h2–h6), see _rich-text-headings.scss
//------------------------------------------------------------------------
@mixin text-size-scale($min: $body-copy-min-size, $max: $body-copy-max-size) {
  @include fs-scale(font-size, (375px: $min, $layout-text-scale-end-bp: $max));
}

@mixin page-title {
  @include fs-scale(font-size, (320px: 41px, $layout-text-scale-end-bp: 100px));
  color: var(--theme-text-color, $c-blue);
  font-family: $chiavettieri;
  font-weight: normal;
  line-height: 1;
}

@mixin intro-text {
  @include fs-scale(font-size, (320px: 20px, $layout-text-scale-end-bp: 22px));
  color: var(--theme-text-color, $c-navy-dark);
  font-family: $chiavettieri;
  letter-spacing: 0.3px;
  line-height: 1.4;
  margin-bottom: fs-rem(60px);
  margin-top: fs-rem(60px);

  @include fs-min-width(560px) {
    line-height: 1.6;
  }
}

@mixin supertitle {
  @include fs-scale(font-size, (320px: 26px, $layout-text-scale-end-bp: 30px));
  color: var(--theme-text-color, $c-blue);
  font-family: $lato;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 1.2;
  text-transform: uppercase;
}

@mixin text-highlight($color: $bg-gold-light) {
  $color-stop: 64%;
  background-image: linear-gradient(to bottom, transparent $color-stop, $color $color-stop);
  background-repeat: repeat-x;
}

@mixin image-caption() {
  $border-width: 15px;
  font-size: fs-rem(16px);
  letter-spacing: 0.3px;
  line-height: 1.5;
  margin-top: fs-rem(10px);
  padding: fs-rem(10px ($border-width + 15px));
  position: relative;

  // Border
  &:before {
    background: $bg-pattern-grid-dots;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: $border-width;
  }
}
