//------------------------------------------------------------------------
// Expandable data tables
//------------------------------------------------------------------------
.DataTable {
  $selector: &;
  $bp-small: 460px;
  $bp-medium: 600px;// indicator toggles
  $bp-row: 768px;
  $bp-wide: 840px;
  $border: 1px solid $bg-red;
  $header-cell-gap: 7px;
  $header-button-right-padding: 15px;
  $cell-right-padding: $header-cell-gap + $header-button-right-padding;
  $link-vert-padding: 10px;// increase click target size
  $cell-vert-padding: 22px - $link-vert-padding;
  margin-top: fs-rem(98px);

  &-top {
    margin-bottom: fs-rem(70px);
  }// end top

  &-row {
    @include fs-min-width($bp-row) {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
    }

    &-secondary {
      @include fs-max-width($bp-row - 1px) {
        margin-top: fs-rem(13px);
      }

      @include fs-min-width($bp-row) {
        text-align: right;
      }
    }// end secondary
  }// end row

  &-heading {
    @include fs-scale(font-size, (360px: 38px, $layout-text-scale-end-bp: 64px));
    color: $c-navy-dark;
    font-family: $chiavettieri;
    font-weight: normal;
    letter-spacing: -0.01em;
    line-height: 1;
    position: relative;// for icon

    @include fs-max-width($bp-wide - 1px) {
      letter-spacing: -0.01em;
    }

    &-icon {
      display: block;
      height: auto;
      margin-bottom: fs-rem(18px);
      width: fs-scale-clamp((360px: 50px, $bp-wide: 100px));

      @include fs-min-width(1366px) {
        margin-right: fs-rem(52px);
        position: absolute;
        right: 100%;
        width: fs-rem(144px);
      }
    }// end icon

    .Tooltip {
      vertical-align: super;
    }
  }// end heading

  &-total {
    color: $c-navy-dark;
    font-size: fs-scale-clamp((360px: 18px, $bp-wide: 22px));
    font-weight: bold;
    letter-spacing: 0.08em;
    line-height: 1.2;
    text-transform: uppercase;
  }// end total

  &-available {
    color: $c-navy-dark;
    font-family: $chiavettieri;
    font-size: fs-scale-clamp((360px: 18px, $bp-wide: 22px));
    font-style: italic;
    letter-spacing: 0.2px;
    line-height: 1.2;
    margin-top: fs-rem(16px);
  }// end available

  &-toggleAll {
    --button-bg-color: #{$bg-blue};
    --button-border-color: #{$bg-navy-dark};
    --button-hover-bg-color: #{$bg-navy};
    --button-hover-border-color: #{$bg-navy-dark};
    @include button-solid;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: fs-scale-clamp((360px: 16px, $bp-wide: 28px));

    @include button-attention {
      .icon {
        transform: scale(1);
      }
    }

    &-icon {
      $icon-width: 17px;
      @include plusMinusIcon($width: $icon-width, $color: currentColor);
      margin-left: fs-rem(6px);
      transform: scale(0.588);// 10/17
      transition: transform 300ms cubic-bezier(0.34, 2, 0.64, 1);// custom easeOutBack
      width: fs-rem($icon-width);
    }// end icon
  }// end toggleAll

  &-list {
    // Undo “l-fullwidth” styles
    @include fs-min-width($bp-wide) {
      position: static;
      transform: none;
      width: auto;
    }
  }// end list

  &-item {
    border-bottom: $border;

    &:first-child {
      border-top: $border;
    }
  }// end item

  &-indicator {
    align-items: center;
    display: flex;
    justify-content: space-between;
    position: relative;

    // Undo “l-padding” styles
    @include fs-min-width($bp-wide) {
      padding-left: 0;
      padding-right: 0;
    }

    &-toggle {
      @include fs-reset-button;
      align-items: center;
      color: $c-blue;
      display: flex;
      justify-content: flex-end;
      padding-bottom: fs-rem(22px);
      padding-top: fs-rem(22px);
      transition: background-color 150ms ease-in-out;
      width: 100%;

      // Allow text to wrap if a user set a larger default font-size
      // https://github.com/Threespot/malala-report-cards/issues/60
      @include fs-max-width($bp-small - 1px) {
        flex-wrap: wrap;
      }

      @include button-attention {
        background-color: $bg-beige;

        .text {
          // text-decoration: underline;
          //
          // // Use thinner underline when supported
          // @supports (text-decoration-thickness: 1px) {
          //   text-decoration-color: currentColor;
          //   text-decoration-line: underline;
          //   text-decoration-thickness: 2px;
          //   text-underline-offset: 4px;
          // }
        }
      }

      // Expanded
      &[aria-expanded="true"] {
        .icon {
          --icon-fill: none;
          transform: rotate(45deg);
        }
      }

      // Make entire row clickable
      &:after {
        @include fs-absolute-fill;
        content: '';
        z-index: 1;
      }

      .text {
        font-size: fs-scale-clamp((320px: 18px, $bp-medium: 30px));
        font-weight: bold;
        line-height: 1.2;
        margin-right: auto;// left align

        @include fs-max-width($bp-small - 1px) {
          max-width: 40%;// keep plus sign from wrapping
        }

        @include fs-min-width($bp-small) {
          width: 100%;// take up as much room as possible
        }

        // Allow words to break if user set a larger default font-size
        // https://github.com/Threespot/malala-report-cards/issues/60
        @include fs-max-width(400px) {
          @include fs-break-word;
        }

        @include fs-min-width($bp-medium) {
          letter-spacing: 0.03em;
        }
      }

      .icon {
        flex-shrink: 0;
        height: auto;
        margin-left: fs-rem(16px);
        transition: fill 180ms ease, transform 300ms cubic-bezier(0.34, 1.6, 0.64, 1);// custom easeOutBack
        width: fs-rem(20px);

        @include fs-min-width($bp-medium) {
          margin-left: fs-rem(30px);
        }
      }
    }// end toggle

    &-score {
      color: $c-blue;
      flex-shrink: 0;
      font-size: fs-scale-clamp((360px: 16px, $bp-medium: 18px));
      line-height: 1.2;
      margin-left: fs-scale-clamp((375px: 20px, 420px: 30px));
      text-decoration: none;
      text-transform: uppercase;

      @include fs-max-width($bp-small - 1px) {
        max-width: fs-rem(110px);// keep plus sign from wrapping
      }

      @include fs-max-width($bp-medium - 1px) {
        flex-shrink: 1;
      }

      @include fs-min-width($bp-medium) {
        letter-spacing: 0.08em;
      }
    }// end score
  }// end indicator

  &-expandable {
    @include expandable;
  }// end expandable

  // Allow tables to scroll horizontally in narrow viewports
  // https://designsystem.digital.gov/components/table/#scrollable-table-2
  &-scrollWrap {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;// iOS “momentum” scrolling

    // Undo “l-padding” styles
    @include fs-min-width($bp-wide) {
      padding-left: 0;
      padding-right: 0;
    }
  }// end scrollWrap

  &-table {
    margin-bottom: fs-rem(20px);
    max-width: fs-rem(858px);
    width: 100%;

    tbody {
      tr {
        position: relative;
        transition: background-color 180ms ease;

        @include fs-min-width($bp-medium) {
          &:hover,
          &:focus-within {
            background-color: #fff;
          }
        }
      }
    }

    th {
      .has-wide-column & {
        &:not(:first-child) {
          min-width: fs-scale-clamp((375px: 138px, $bp-row: 270px));
        }
      }

      &:not(:last-child) {
        padding-right: fs-rem(7px);
      }

      &:first-child {
        width: 99%;

        #{$selector}--countries & {
          width: auto;
        }
      }

      &:not(:first-child) {
        text-align: right;

        & > * {
          margin-left: auto;// right align
        }
      }
    }

    td {
      @include fs-scale(font-size, (320px: 16px, $bp-wide: 20px));
      color: $bg-navy;
      padding: fs-rem($cell-vert-padding $cell-right-padding $cell-vert-padding 10px);
      vertical-align: middle;

      @include fs-max-width($bp-medium - 1px) {
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      &:not(:first-child) {
        text-align: right;
      }
    }

    &-heading {
      @include fs-font-smoothing;
      align-items: center;
      background-color: $bg-gray-light;
      border-radius: 100px;
      border: 1px solid transparent;
      color: $c-blue;
      display: flex;
      font-size: fs-scale-clamp((375px: 15px, 420px: 16px));
      font-weight: normal;// override default th style
      letter-spacing: 0.05em;
      line-height: 1;
      min-height: fs-rem(40px);
      padding: fs-rem(6px 15px 6px 12px);
      text-transform: uppercase;
      transition: all 150ms ease-in-out;
      white-space: nowrap;
      width: min-content;

      @include button-attention {
        border-color: currentColor;
      }

      // Currently sorted
      &[data-sort] {
        background-color: $bg-blue;
        color: #fff;
      }

      // Ascending
      &[data-sort="asc"] {
        --arrow-down-opacity: 0;
      }

      // Descending
      &[data-sort="desc"] {
        --arrow-up-opacity: 0;
      }

      .icon--arrow-up-down {
        fill: currentColor;
        margin-right: fs-rem(10px);
      }

      // Tooltip adjustments when inside table sort buttons
      .Tooltip {
        margin-left: fs-rem(10px);

        &-toggle {
          display: block;

          .icon {
            display: block;
          }
        }// end toggle
      }
    }// end heading

    // Country/indicator name
    &-name {
      letter-spacing: 0.03em;
      line-height: 1.3;

      &.has-link {
        padding-right: 0;
      }

      #{$selector}--countries & {
        font-weight: bold;
        letter-spacing: 0.05em;

        &.is-wide {
          @include fs-max-width($bp-medium - 1px) {
            min-width: fs-rem(166px);
          }
        }
      }

      &-link {
        $arrow-icon-width: 11px;
        color: $c-blue;
        display: inline-block;
        padding-bottom: fs-rem($link-vert-padding);
        padding-top: fs-rem($link-vert-padding);

        @include fs-attention {
          text-decoration: underline;
        }

        // Apply icon to <span> wrapper to keep icon from
        // wrapping to a new line (requires “display: inline;”).
        // This means we have to add custom styles for the arrow
        // instead of using the
        .icon-wrap {
          @include link-add-icon(
            $icon-name: 'arrowRight',
            $icon-width: $arrow-icon-width
          );

          // Adjust styles to allow for animating the icon position
          &:after {
            display: inline-block;
            margin-left: fs-rem(4px);
          }
        }
      }// end link
    }// end name

    &-value {
      font-weight: bold;
      line-height: 1;

      &.is-empty {
        white-space: nowrap;// keep “No Data” from wrapping
      }

      // Used for longer text values (>7 characters)
      &.is-long {
        @include fs-scale(font-size, (320px: 14px, $bp-wide: 18px));
        line-height: 1.25;
      }
      #{$selector}--countries & {
        font-weight: normal;

        &:last-child {
          font-weight: bold;
        }
      }
    }// end value
  }// end table
}
