//------------------------------------------------------------------------
// Card promo
//
// Used by both of these components:
// https://github.com/Threespot/malala-report-cards/issues/13
// https://github.com/Threespot/malala-report-cards/issues/15
//------------------------------------------------------------------------
.Card {
  $border-width: 10px;
  background-color: $bg-green-lighter;
  margin-bottom: fs-scale-clamp((360px: 60px, $layout-text-scale-end-bp: 80px));
  margin-top: fs-scale-clamp((360px: 45px, $layout-text-scale-end-bp: 80px));
  position: relative;
  padding: fs-rem(30px 20px 40px (20px + $border-width));

  @include fs-min-width(600px) {
    padding: fs-rem(40px 40px 40px (40px + $border-width));
  }

  // Left border
  &:before {
    background: $bg-pattern-holly;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: $border-width;
  }

  &-meta {
    align-items: center;
    color: $bg-green-dark;
    display: flex;
    justify-content: space-between;
    margin-bottom: fs-rem(25px);

    &-label {
      font-size: fs-rem(18px);
      font-weight: bold;
      letter-spacing: 0.08em;
      line-height: 1;
      text-transform: uppercase;
    }// end label

    &-date {
      font-family: $chiavettieri;
      font-style: italic;
      letter-spacing: 0.2px;
      line-height: 1;
    }// end date
  }// end meta

  // Note: Has “f-h3” class
  &-title {
    margin-bottom: fs-rem(20px);
  }// end title

}// end Card
