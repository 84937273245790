//------------------------------------------------------------------------
// Equal-column layouts
//------------------------------------------------------------------------
// Grid-based layout (items aligned left to right_)
[data-column-layout] {
  align-items: stretch;
  column-gap: 22px;
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 1em;
  margin-top: 1em;
  row-gap: 1em;
}

@function repeat($string, $n, $delimiter: ' ') {
  $output: '';
  @for $i from 1 through $n {
    $output: $output + $string + if($i < $n, $delimiter, '');
  }
  @return unquote($output);
}

@for $i from 2 through 6 {
  [data-columns*="#{$i}"] {
    @include fs-min-width(map-get($layout-columns-bp, $i)) {
      margin-bottom: 2em;
      margin-top: 2em;
      grid-template-columns: repeat('1fr', $i);
    }
  }
}

// CSS columns layout (aligned top to bottom)
[data-columns] {
  column-gap: 50px;

  & > li {
    break-inside: avoid;
  }
}

@for $i from 2 through 6 {
  [data-columns*="#{$i}"] {
    @include fs-min-width(map-get($layout-columns-bp, $i)) {
      column-count: $i;
    }
  }
}
