//------------------------------------------------------------------------
// Conclusion
//------------------------------------------------------------------------
.Conclusion {
  // Prevent overlap in Safari
  // https://github.com/Threespot/malala-report-cards/issues/29
  .KeyTakeaways + & {
    position: relative;
    z-index: 2;
  }

  &-supertitle {
    @include fs-scale(margin-bottom, (375px: 26px, $layout-homehero-bp: 41px));
    @include fs-scale(padding-top, (375px: 77px, $layout-homehero-bp: 137px));
    color: $bg-gold-light;
  }

  &-title {
    @include fs-scale(margin-bottom, (375px: 15px, $layout-homehero-bp: 58px));
    line-height: 1.1;
  }

  &-responsive {
    @include fs-scale(padding-bottom, (375px: 214px, $layout-homehero-bp: 189px));
  }

  &-description {
    max-width: 512px;
  }

  &-image {
    height: 182px;
    margin-left: auto;
    margin-top: fs-rem(32px);
    width: 304px;
    position: absolute;
    right: 0;
    bottom: 0;

    @include fs-min-width($layout-homehero-bp) {
      height: 466px;
      width: 40%;
      z-index: 3;
    }

    &:after {
      background: $bg-pattern-holly;
      bottom: -88px;
      content: "";
      height: 88px;
      position: absolute;
      right: 0;
      width: 100%;
    }

    img {
      @include fs-absolute-fill;
      object-fit: cover;
    }
  }
}
