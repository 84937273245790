//------------------------------------------------------------------------
// Layout helper classes
//------------------------------------------------------------------------
// Note: Just checking for [class*="l-wrap"] can result in false positives
//       e.g. “Modal-wrap” would match
[class^="l-wrap"],
[class*=" l-wrap"] {
  margin-left: auto;
  margin-right: auto;
}

.l {
  // Used by image slides
  &-padding {
    @include default-padding;
  }// end padding

  &-wrap {
    max-width: fs-rem($layout-max-width);

    &--wide {
      max-width: fs-rem($layout-max-width-wide);

      .l-wrap {
        margin-left: 0;
      }
    }// end -narrow
  }// end wrap

  &-block-wrap {
    & > *:last-child {
      margin-bottom: fs-scale-clamp((360px: 60px, $layout-max-width-bp: 96px));
    }
  }// end block-wrap

  // Allow element to span viewport width regardless of parent width
  // NOTE: These styles only work when the parent wrapper is centered.
  &-fullwidth {
    @include layout-fullwidth;
  }

  &-width-100 {
    width: 100%;
  }

  &-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }// end center

  // Custom 2-col layout for body copy (typically right column is an image)
  // Note: See _column-helpers.scss for equal-width column helpers
  &-columns {
    margin-bottom: 2em;
    margin-top: 2em;

    @include fs-min-width($layout-2-col-bp) {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;

      &--centered {
        align-items: center;
      }
    }

    & > * {
      @include fs-max-width($layout-2-col-bp - 1px) {
        &:not(:last-child) {
          margin-bottom: fs-rem(60px);
        }
      }

      @include fs-min-width($layout-2-col-bp) {
        max-width: 66%;
        min-width: fs-rem(220px);

        &:not(:last-child) {
          margin-right: 9%;
        }
      }
    }

    img {
      @include fs-max-width($layout-2-col-bp - 1px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }// end columns

  // Left/right alignment using float
  &-align {
    $gutter: 9%;

    &-right {
      @include fs-min-width($layout-2-col-bp) {
        clear: right;
        float: right;
        margin-bottom: 1em;
        margin-left: $gutter;
      }
    }// end right

    &-left {
      @include fs-min-width($layout-2-col-bp) {
        clear: left;
        float: left;
        margin-bottom: 1em;
        margin-right: $gutter;
      }
    }// end left
  }// end align

  // Flexbox helper, no breakpoint
  &-flex {
    $gutter: 30px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-left: fs-rem(-$gutter * 0.5);
    margin-bottom: 2em;
    margin-top: calc(2em - #{fs-rem($gutter)});
    width: calc(100% + #{fs-rem($gutter * 0.5)});

    &--center {
      justify-content: center;
    }

    &--space-between {
      justify-content: space-between;
    }

    &--space-evenly {
      justify-content: space-evenly;
    }

    & > * {
      margin-left: fs-rem($gutter * 0.5);
      margin-right: fs-rem($gutter * 0.5);
      margin-top: fs-rem($gutter);
    }
  }// end flex

} // end l
