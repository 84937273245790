//------------------------------------------------------------------------
// Data summary
// https://github.com/Threespot/malala-report-cards/issues/23
//------------------------------------------------------------------------
.Summary {
  color: $c-blue;
  font-family: $chiavettieri;
  font-size: fs-scale-clamp((360px: 20px, $layout-text-scale-end-bp: 22px));
  letter-spacing: 0.3px;
  line-height: 1.4;
  margin-bottom: fs-scale-clamp((360px: 45px, $layout-text-scale-end-bp: 79px));
  margin-left: auto;
  margin-right: auto;
  margin-top: fs-scale-clamp((360px: 45px, $layout-text-scale-end-bp: 97px));
  max-width: fs-rem(610px);
  text-align: center;

  @include fs-max-width(580px) {
    text-wrap: balance
  }
}
