//------------------------------------------------------------------------
// Color helpers for data values (uses “data-color” attribute)
//
// Note: The color info is stored in the CSV files
//------------------------------------------------------------------------
@use 'sass:math';

// Base pill styles
[data-color] {
  &:after {
    $dot-size: 23px;
    $collapsed-height: 10px;
    --dot-size: #{fs-rem($dot-size)};
    background-color: var(--data-color);
    border-radius: 10px / 20px;
    content: '';
    display: inline-block;
    height: var(--dot-size);
    margin-left: fs-rem(6px);
    transform: scaleY(math.div($collapsed-height, $dot-size));
    transition: all 240ms ease;
    vertical-align: middle;
    width: var(--dot-size);

    tr:hover & {
      border-radius: 50%;
      transform: scaleY(1);
    }
  }

  // Hide if no value
  &.is-empty {
    &:after {
      display: none;
    }
  }
}

// Colors
@each $name, $color in $data-colors {
  [data-color="#{$name}"] {
    --data-color: #{$color};
  }
}
