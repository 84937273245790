//------------------------------------------------------------------------
// Navigation
//------------------------------------------------------------------------
.Nav {
  $item-left-border-width: 5px;
  $item-left-border: $item-left-border-width solid var(--border-left-color);
  $item-left-padding: 12px;
  $item-horz-margin: fs-scale-clamp(($layout-nav-bp: 30px, $layout-nav-wide-bp: 54px));
  $item-vert-margin: 46px;
  $item-col-gap: 40px;
  --border-left-color: #{$bg-blue-light};

  @include fs-max-width($layout-nav-bp - 1px) {
    @include expandable;
    background-color: $bg-gray-light;
    box-shadow: inset 0 -5px 0 $bg-gold;// `border` would show when collapsed
    left: 0;
    position: absolute;
    top: 100%;
    transition: background-color 180ms ease, grid-template-rows 500ms fs-easing("easeOutCubic");
    width: 100%;
    z-index: fs-zindex('nav');
  }

  // Cancel out “l-padding”
  @include fs-min-width($layout-nav-bp) {
    flex-grow: 1;
    padding-left: 0;
    padding-right: 0;
  }

  &-wrap {
    // Allow country menu to extend beyond nav menu
    [data-whatclasses*="autocomplete__input"] & {
      overflow: visible;
    }
  }// end wrap

  &-content {
    @include fs-max-width($layout-nav-bp - 1px) {
      padding-bottom: fs-rem(36px);
      padding-top: fs-rem(6px);
    }

    @include fs-min-width($layout-nav-bp) {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      margin-left: auto;// right align
      position: relative;// for nav menu positioning
      width: fit-content;// for nav menu positioning
    }

    .no-js & {
      flex-wrap: wrap;
    }
  }// end content

  &-item {
  }// end item

  // Submenu toggle
  &-toggle {
    @include fs-reset-button;
    align-items: center;
    border-left: $item-left-border;
    color: $c-navy-dark;
    display: flex;
    font-size: fs-rem(16px);
    font-weight: $lato-black;
    justify-content: space-between;
    letter-spacing: 0.1em;
    padding: fs-rem(13px 16px 13px $item-left-padding);
    text-transform: uppercase;
    transition: all 180ms ease;
    width: 100%;

    @include fs-min-width($layout-nav-bp) {
      flex-shrink: 0;
      width: fit-content;
    }

    @include button-attention {
      background-color: rgba($bg-blue-light, 0.4);
      border-left-color: $bg-blue;
    }

    &[aria-expanded="true"] {
      background-color: rgba($bg-blue-light, 0.4);
      border-left-color: $bg-blue;

      .icon {
        transform: rotateX(180deg);
      }
    }

    .icon {
      flex-shrink: 0;
      height: auto;
      margin-left: fs-rem(16px);
      transition: transform 180ms ease;
      width: fs-rem(16px);
    }

    &-text {
      &.is-short {
        display: none;

        @include fs-media((min-width: $layout-nav-bp, max-width: $layout-nav-wide-bp - 1px)) {
          display: block;
        }
      }

      &.is-long {
        @include fs-media((min-width: $layout-nav-bp, max-width: $layout-nav-wide-bp - 1px)) {
          display: none;
        }
      }
    }// end text
  }// end toggle

  &-menu {
    @include expandable($speed: 500ms, $easing: fs-easing("easeOutCubic"));

    @include fs-max-width($layout-nav-bp - 1px) {
      border-left: $item-left-border;
      margin-bottom: fs-rem($item-vert-margin);
    }

    @include fs-min-width($layout-nav-bp) {
      left: 0;
      margin-top: fs-rem(10px);
      max-width: fs-rem(852px);
      position: absolute;
      top: 100%;
      width: 100%;
    }

    // Center beneath menu toggle
    // Note: Set breakpoint so menu aligns with logo
    @include fs-min-width(1160px) {
      $toggle-width: 290px;// magic number, update if toggle styles change
      left: fs-rem($toggle-width * 0.5);
      transform: translateX(-50%);
    }

    .no-js & {
      order: 3;
      position: static;
    }

    // Wrapper required by “expand-toggle” script
    &-wrap {}

    &-list {
      @include fs-max-width($layout-nav-bp - 1px) {
        padding: fs-rem(20px 18px 23px 18px);
      }

      @include fs-min-width($layout-nav-bp) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: $item-col-gap;
        padding: fs-rem(40px 40px 42px 40px);
      }
    }// end list

    &-item {
      &:not(:last-child) {
        margin-bottom: fs-rem(40px);

        @include fs-min-width($layout-nav-bp) {
          margin-bottom: fs-rem(46px);
        }
      }

      @include fs-min-width($layout-nav-bp) {
        width: calc(50% - #{$item-col-gap});
      }

      &.has-border {
        position: relative;
        padding-top: fs-rem(16px);

        @include fs-min-width($layout-nav-bp) {
          text-align: center;
        }

        &:before {
          border-top: 1px solid $bg-pink;
          content: '';
          left: 0;
          position: absolute;
          top: 0;
          width: 100px;

          @include fs-min-width($layout-nav-bp) {
            left: 50%;
            margin-left: fs-rem(-5px);// visually center
            transform: translateX(-50%);
          }
        }
      }
    }// end item

    &-link {
      @include link-add-icon(
        $icon-name: 'arrowRight',
        $icon-width: 11px
      );
      color: currentColor;
      font-family: $chiavettieri;
      font-size: fs-rem(26px);
      line-height: 1.25;

      @include fs-attention {
        color: $bg-gold-light;
      }

      &.is-small {
        font-family: $lato;
        font-size: fs-rem(16px);
        font-weight: bold;
        letter-spacing: 0.04em;
        line-height: 1.1;
        text-transform: uppercase;
      }
    }// end link

    &-description {
      font-size: fs-rem(16px);
      letter-spacing: 0.3px;
      line-height: 1.375;
      margin-top: fs-rem(4px);

      @include fs-min-width($layout-nav-bp) {
        margin-top: fs-rem(14px);
      }
    }// end description
  }// end menu

  // Country search field
  &-search {
    $input-height: 42px;
    color: $c-navy-dark;
    display: flex;
    justify-content: space-between;
    min-height: fs-rem(42px);
    position: relative;// for autocomplete menu
    transition: all 180ms ease;

    @include fs-max-width($layout-nav-bp - 1px) {
      margin-bottom: fs-rem($item-vert-margin);
    }

    @include fs-min-width($layout-nav-bp) {
      margin-left: $item-horz-margin;
    }

    &:hover {
      --border-left-color: #{$bg-blue};
      background-color: rgba($bg-blue-light, 0.4);
    }

    [data-whatclasses*="autocomplete__input"] &,
    &:focus-within {
      --submit-button-bg: #{$bg-blue-light};
      --submit-button-color: #{$bg-navy-dark};
      --input-color: #fff;
      background-color: $bg-navy-dark;
      color: #fff;
    }

    // No-js fallback <select>
    &-select {
      border-radius: 0;
      border-left: $item-left-border;
      display: block;
      font-size: fs-rem(16px);
      padding: fs-rem(12px 16px);// left align with input text
      width: 100%;

      &:focus {
        border-left-color: $bg-blue;
      }
    }// end select

    // This plain div wrapper is created by “accessible-autocomplete”
    & > div {
      flex-grow: 1;
    }

    .autocomplete {
      &__wrapper {
        height: 100%;
        position: static;// override default
      }

      // Note: “autocomplete__hint” is added when `autoselect: true` is set.
      //       It creates an absolutely-positioned readonly input underneath.
      &__hint,
      &__input {
        background-color: transparent;// override default styles
        border: 0;// override default styles
        font-size: fs-rem(16px);
        font-weight: bold;
        height: 100%;
        letter-spacing: 0.04em;
        line-height: 1;
        padding-left: fs-rem($item-left-padding);
        text-transform: uppercase;
      }

      &__hint {
        border-left: $item-left-border-width solid transparent;
        color: currentColor;
        opacity: 0.8;
      }

      &__input {
        border-left: $item-left-border;
        color: var(--input-color, $c-navy-dark);
        transition: all 180ms ease;

        @include fs-min-width($layout-nav-bp) {
          min-width: fs-rem(218px);
        }

        &--focused {
          --input-color: #fff;
          @include fs-font-smoothing;
          border-left-color: $bg-blue;
          box-shadow: none;// override default styles
          outline: none;// override default styles

          &::placeholder {
            font-weight: bold;
          }
        }

        &::placeholder {
          color: currentColor;
          font-weight: $lato-black;
          letter-spacing: 0.1em;
        }
      }

      &__menu {
        border: 1px solid $bg-blue-border;
        max-height: fs-rem(288px);// override default
        width: 100%;
      }

      &__option {
        font-size: fs-rem(16px);
        font-weight: bold;
        letter-spacing: 0.04em;
        line-height: 1;
        padding: fs-rem(12px 16px);// left align with input text
        text-transform: uppercase;

        &:not(:last-child) {
          border-bottom: 1px solid $bg-blue-border;
        }
      }
    }

    &-submit {
      @include fs-reset-button;
      aspect-ratio: 1;
      color: currentColor;
      flex-shrink: 0;
      position: relative;
      transition: all 180ms ease;
      width: fs-rem($input-height);

      @include fs-attention {
        background-color: var(--submit-button-bg, $bg-navy-dark);
        color: var(--submit-button-color, #fff);
      }

      .no-js & {
        border: 1px solid $bg-navy-dark;
        border-left: 0;
        border-radius: 0;
      }

      .icon {
        fill: currentColor;
        height: auto;
        left: 53%;// visually center
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: fs-rem(15px);
      }
    }// end submit
  }// end search

  &-cta {
    @include fs-min-width($layout-nav-bp) {
      flex-shrink: 0;
      margin-left: $item-horz-margin;
    }
  }// end cta
}// end Nav
