//------------------------------------------------------------------------
// Add plus/minus icon to expandable toggles
//------------------------------------------------------------------------
// stylelint-disable no-descending-specificity
@mixin plusMinusIcon($width: 10px, $thickness: 2px, $position: 0, $color: currentColor) {
  position: relative;

  &:before,
  &:after {
    border-top: $thickness solid $color;
    content: '';
    position: absolute;
    right: $position;
    top: 50%;
    transform: translateY(-50%);
    width: fs-rem($width);

    .no-js & {
      display: none;
    }
  }
  // Plus sign
  &:after {
    transform: rotate(90deg) translateX(-$thickness * 0.5);
    transition: transform 250ms ease;
    transform-origin: 50% 50%;
  }

  // Minus sign
  // Note: Since the data table “expand all” toggle logic is more complex than a
  //       regular single toggle button, we need to use a class to update the icon.
  .is-active > & {
    &:after {
      transform: rotate(0) translateY(-50%);
    }
  }
}
