//------------------------------------------------------------------------
// Country page header (used for low-income and donor countries)
//------------------------------------------------------------------------
.CountryHeader {
  $bp-label-row: 560px;// when label no longer wraps
  $bp-wide: 768px;
  $bp-end-scale: 1366px;
  $title-min-vert-margin: 30px;
  --theme-text-color: #{$c-navy};
  background-color: $bg-blue-lighter;
  padding-bottom: fs-rem(32px);
  padding-top: fs-rem(10px);

  @include fs-min-width($bp-wide) {
    padding-bottom: fs-scale-clamp(($bp-wide: 56px, $bp-end-scale: 120px - $title-min-vert-margin));
    padding-top: fs-scale-clamp(($bp-wide: 28px, $bp-end-scale: 58px));
  }

  &-wrap {
    margin-left: auto;
    margin-right: auto;
    max-width: fs-rem($layout-max-width-full);

    @include fs-min-width($bp-wide) {
      display: flex;
      justify-content: space-between;
    }
  }// end wrap

  &-primary {
    @include fs-break-word;// for long country names (e.g. Turkmenistan)

    @include fs-min-width($bp-wide) {
      display: flex;
      flex-direction: column;
      max-width: 70%;
      padding-right: 5.25%;
    }
  }

  &-label {
    align-items: center;
    color: var(--theme-text-color);
    display: flex;
    margin-bottom: fs-rem(32px);

    @include fs-min-width($bp-wide) {
      margin-bottom: auto;
    }

    // i.e. “Low-Income” or “Donor Country”
    &-group {
      flex-shrink: 0;
      font-size: fs-scale-clamp((360px: 16px, $bp-wide - 1px: 22px));
      letter-spacing: 0.08em;
      line-height: 1.2;
      text-transform: uppercase;

      @include fs-min-width($bp-wide) {
        font-size: fs-scale-clamp(($bp-wide: 16px, $bp-end-scale: 22px));
      }

      // Donor countries
      &:only-child {
        br {
          display: none;
        }
      }

      // Low-income countries
      &:not(:last-child) {
        margin-right: fs-rem(15px);
        padding-right: fs-rem(15px);
        position: relative;

        // Vertical rule
        &:after {
          border-right: 1px solid $bg-pink;
          content: '';
          height: fs-rem(32px);
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        br {
          @include fs-media((min-width: $bp-label-row, max-width: $bp-wide - 1px)) {
            display: none;
          }

          @include fs-min-width(860px) {
            display: none;
          }
        }
      }
    }// end group

    &-region {
      font-family: $chiavettieri;
      font-size: fs-scale-clamp((360px: 16px, $bp-wide - 1px: 22px));
      font-style: italic;
      letter-spacing: 0.2px;
      line-height: 1.2;

      @include fs-min-width($bp-wide) {
        font-size: fs-scale-clamp(($bp-wide: 16px, $bp-end-scale: 22px));
      }
    }// end region
  }// end label

  &-title {
    font-size: fs-scale-clamp((320px: 38px, $bp-wide - 1px: 90px));

    @include fs-min-width($bp-wide) {
      font-size: fs-scale-clamp(($bp-wide: 53px, $bp-end-scale: 100px));
      margin-bottom: auto;
      padding-bottom: fs-rem($title-min-vert-margin);
      padding-top: fs-rem($title-min-vert-margin);
    }
  }// end title

  &-stats {
    @include fs-max-width($bp-wide - 1px) {
      margin-top: fs-rem(32px);
      max-width: fs-scale-clamp((360px: 262px, $bp-wide - 1px: 400px));
    }

    // Set as flex column so we can align the average on donor countries
    @include fs-min-width($bp-wide) {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
    }
  }// end stats

  &-stat {
    color: var(--theme-text-color);
    align-items: center;
    display: flex;

    &:not(:last-child) {
      margin-bottom: fs-rem(30px);
    }

    // Donor countries only show the average
    &:only-child {
      @include fs-scale(margin-top, ($bp-wide: 78px, 960px: 65px), $initial: false);
    }

    // Color dot overrides
    &:after {
      @include dotExpand;
      margin-left: fs-rem(17px);

      @include fs-max-width(559px) {
        --dot-size: #{fs-rem(16px)};
      }
    }

    .icon {
      --icon-color: #{$bg-blue};
      @include fadeInRotate;
      margin-right: fs-scale-clamp((360px: 12px, $bp-wide - 1px: 14px));
    }

    &-label {
      color: $c-navy-dark;
      font-size: fs-scale-clamp((360px: 16px, $bp-wide - 1px: 22px));
      letter-spacing: 0.08em;
      line-height: 1.2;
      margin-right: fs-rem(32px);
      text-transform: uppercase;

      @include fs-min-width($bp-wide) {
        font-size: fs-scale-clamp(($bp-wide: 16px, $bp-end-scale: 22px));
      }

      .is-average & {
        background-color: rgba($bg-blue-light, 0.5);
        padding: fs-rem(8px 7px);
      }
    }// end label

    &-score {
      font-size: fs-scale-clamp((360px: 28px, $bp-wide - 1px: 50px));
      font-weight: $lato-light;
      letter-spacing: -0.02em;
      line-height: 1;
      margin-left: auto;// right align

      @include fs-min-width($bp-wide) {
        font-size: fs-scale-clamp(($bp-wide: 28px, $bp-end-scale: 50px));
      }

      .is-average & {
        font-size: fs-scale-clamp((360px: 36px, $bp-wide - 1px: 80px));

        @include fs-min-width($bp-wide) {
          font-size: fs-scale-clamp(($bp-wide: 36px, $bp-end-scale: 80px));
        }
      }
    }// end score
  }// end stat
}
